.homepage-about-container {
  width: 100%;
}

.homepage-about-container .container {
  max-width: 1300px;
  width: 100%;
  margin: auto;
  padding: 3rem;
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.homepage-about-container .container-inner {
  padding-top: 8rem;
  margin-bottom: 3rem;
}

.homepage-about-container h1 {
  font-size: 3rem;
  color: #1a2249;
  font-family: "Libre Baskerville", serif;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  max-width: 1000px;
  width: 100%;
  margin: auto;
}

.homepage-about-container p {
  font-size: 1.3rem;
  color: #1a2249;
  padding-block: 1.2rem;
  max-width: 60%;
  width: 100%;
  margin: auto;
  text-align: center;
  font-family: "Onest", sans-serif;
}

.homepage-about-container .images-container {
  display: flex;
  gap: 1rem;
  margin-block: 3rem;
  align-items: center;
  justify-content: center;
}
.homepage-about-container img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
}

.homepage-about-container .image-wrapper {
  position: relative;
  height: 20rem;
  max-width: 30rem;
}

.homepage-about-container .image-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    transparent 15%,
    rgba(2, 84, 131, 0.8) 100%
  );
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  padding: 3rem;
}

.homepage-about-container .image-overlay h1 {
  font-size: 1.7rem;
  font-family: "Onest", sans-serif;
  text-transform: none;
  margin: 0;
  text-align: left;
  color: white;
}

.homepage-about-container .image-overlay p {
  margin: 0;
  padding: 0;
  text-align: left;
  color: white;
  font-weight: 500;
  width: 70%;
}

.homepage-about-container .buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.homepage-about-container .btn-large {
  border: none;
  font-weight: bold;
  color: white;
  padding: 0.7rem 1.2rem;
  border-radius: 2rem;
  width: 15rem;
}

.homepage-about-container .btn-large.color-p1 {
  background-color: #24b394;
}

.homepage-about-container .btn-large.color-p2 {
  background-color: #0471af;
}

.homepage-about-container .logos-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
  padding-block: 5rem;
  background-image: linear-gradient(to right, #48b98d 15%, #0185cf 100%);
  justify-content: center;
  gap: 2rem;
}

.homepage-about-container .logos-container .item-wrapper {
  max-width: 20rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage-about-container .logos-container .item-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 10rem;
  width: 100%;
  align-items: center;
}

.homepage-about-container .logo-wrapper {
  width: fit-content;
  padding: 1.5rem;
  background-color: rgba(196, 223, 198, 0.733);
  border-radius: 100%;
}

.homepage-about-container .item-container img {
  height: 3rem;
  width: 3rem;
  object-fit: contain;
}

.homepage-about-container .item-container h1 {
  font-size: 1.3rem;
  font-family: "Onest", sans-serif;
  text-transform: none;
  margin: 0;
  text-align: center;
  color: white;
  font-weight: 500;
}

@media (max-width: 768px) {
  .homepage-about-container .container-inner {
    margin-bottom: 3rem;
    margin-top: 0;
    padding-top: 0;
  }
  .homepage-about-container h1 {
    font-size: 2rem;
    width: fit-content;
    margin-inline: 1.5rem;
  }

  .homepage-about-container p {
    font-size: 1rem;
    max-width: 100%;
  }
  .homepage-about-container .images-container {
    display: flex;
    gap: 1rem;
    margin-block: 3rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .homepage-about-container .container {
    padding: 1.5rem;
  }
  .homepage-about-container .buttons-container {
    flex-direction: column;
  }
  .homepage-about-container .image-wrapper {
    height: 15rem;
    width: 100%;
  }
  .homepage-about-container .logos-container {
    flex-wrap: wrap;
    padding-block: 3rem;
  }
  .homepage-about-container .item-container {
    width: 100%;
    height: 100%;
  }
  .homepage-about-container .logos-container .item-wrapper {
    width: fit-content;
    margin-block: 1rem;
    width: 150px;
    height: 150px;
  }
  .homepage-about-container .item-container h1 {
    font-size: 1.1rem;
  }
  .homepage-about-container .item-container img {
    height: 2.5rem;
    width: 2.5rem;
  }
  .homepage-about-container .image-overlay {
    padding: 1.5rem;
  }
  .homepage-about-container .image-overlay h1 {
  font-size: 1.3rem;
  }
}
