.homepage-footer-container {
  width: 100%;
  /* background-color: #0185cf; */
}

/* font-family: 'Libre Baskerville', serif;
      font-family: 'Montserrat', sans-serif;
      font-family: 'Onest', sans-serif; */

.homepage-footer-container .container {
  max-width: 1300px;
  width: 100%;
  margin: auto;
  padding-inline: 3rem;
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 3rem;
}

.homepage-footer-container .container-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homepage-footer-container .logo-social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.homepage-footer-container .logo-social .logo img {
  height: 5rem;
  object-fit: contain;
}
.homepage-footer-container .logo-social .social img {
  height: 2rem;
  width: 2rem;
  object-fit: contain;
}

.homepage-footer-container .logo-social .social span {
  background: rgb(26, 34, 73);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: white;
  font-size: 24px;
}

.homepage-footer-container .logo-social .social {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.homepage-footer-container .nav {
  margin-block: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.homepage-footer-container .nav button {
  border: 0;
  background-color: transparent;
  font-weight: 500;
  color: rgb(26, 34, 73);
  font-family: "Onest", sans-serif;
  font-size: 1.5rem;
}

.homepage-footer-container .copyright {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #c4c4c4;
  padding-block: 1rem;
}

.homepage-footer-container .copyright span {
  color: #868686;
  font-size: 1rem;
}

.homepage-footer-container .copyright .space {
  padding: 0;
  padding-inline: 2rem;
  cursor: default;
}

.homepage-footer-container .imprint-dataprivacy {
  cursor: pointer;
}

@media (max-width: 768px) {
  .homepage-footer-container .logo-social {
    flex-direction: column;
    gap: 2rem;
  }
  .homepage-footer-container .copyright span {
    font-size: 0.8rem;
  }
  .homepage-footer-container .nav {
    gap: 2rem;
    flex-direction: column;
  }
}
