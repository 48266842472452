.availbality_slots_container {
  display: grid;
  grid-template-columns: 20% 80%;
  gap: 24px;
  margin-block: 40px;
  max-width: 1100px;
}

.availbality_slots_select {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  gap: 16px;
}

.add_new_availability {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

.add_new_availability p {
  color: #d8000c;
  background-color: #fecaca;
  border: 1px solid #f43f5e;
  padding: 10px 20px;
  font-size: 12px;
  text-align: center;
  text-wrap: balance;
  border-radius: 5px;
}

.select_input_wrapper {
  max-width: 300px;
  width: 100%;
  justify-self: center;
}

.add_new_availability {
  margin-block: 20px 40px;
  display: flex;
  justify-content: flex-end;
}

.add_new_availability button {
  background: var(--main-text2-color);
  color: white;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
  width: fit-content;
}

.add_new_availability button:focus {
  opacity: 0.9;
  outline: transparent;
}

.availability_index {
  display: none;
}

.delete_availability {
  background-color: #f87171;
  color: white;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 3px;
  border: none;
  display: grid;
  place-items: center;
}

.close_error {
  background-color: #f87171 !important;
  padding: 5px 10px !important;
  margin-top: 20px;
}

.delete_availability:focus {
  outline: transparent;
}

.availability_slots_header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.availability_slots_header label {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.availability_slots_header select {
  min-width: 200px;
  padding: 5px 10px;
  border: 1px solid gray;
  outline: transparent;
  border-radius: 3px;
}

.cal_section_filter {
  display: flex;
  padding: 40px 20px 20px 20px;
  gap: 16px;
  justify-content: flex-end;
}

.cal_section_unblock_btn {
  background: #115dfc;
  border-radius: 7px;
  font-size: 14px;
  padding: 8px 16px;
  color: #ffffff;
  cursor: pointer;
  border: none;
  min-width: 70px;
}

.cal_section_block_btn {
  font-size: 14px;
  color: rgb(243, 18, 96);
  font-weight: 600;
  background: rgba(243, 18, 96, 0.19);
  padding: 8px 16px;
  outline: transparent;
  border: transparent;
  border-radius: 7px;
}

.toast_class {
  text-align: center;
}

.toast_class svg,
.toast_class button {
  display: none;
}

@media (max-width: 1600px) {
  .availbality_slots_container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .availability_slots_header {
    flex-direction: column;
  }

  .availabilit_to {
    display: none;
  }

  .availbality_slots_select {
    grid-template-columns: 1fr;
    padding-inline: 20px;
  }

  .select_input_wrapper {
    max-width: unset;
  }

  .select_input_wrapper {
    justify-self: flex-start;
  }

  .add_new_availability {
    justify-content: flex-start;
  }

  .availability_index {
    display: block;
  }
}

@media (max-width: 420px) {
  .availability_slots_header label {
    margin-inline: auto;
  }
}
