.modal_toggler_button {
  background: transparent;
  border: 1px solid #115dfc;
  color: #115dfc;
  outline: transparent;
}

.dashboard.modal_toggler_button {
  background-color: #115dfc;
  color: white;
  font-size: 12px;
}

.invite_modal_wrapper {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  z-index: 100;
}

.hide_invite_modal {
  display: none;
}

.invite_modal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  height: fit-content;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  border-radius: 11px;
  overflow: hidden;
}

.invite_modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid rgb(229, 231, 235);
  width: 100%;
}

.invite_modal_header h3,
.invite_modal_subheader h3 {
  font-size: 20px;
  font-weight: 500;
  color: rgb(17, 24, 39);
}

.invite_modal_header .close {
  color: rgb(156, 163, 175);
  cursor: pointer;
  font-size: 30px;
  transition: all 0.3s ease;
  padding: 5px;
  border-radius: 3px;
}

.invite_modal_header .close:hover {
  background: rgb(229, 231, 235);
  color: rgb(17, 24, 39);
}

.invite_modal_body {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.invite_modal_body label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  font-size: 14px;
  color: #8b8e98;
  font-weight: 600;
}

.invite_modal_body input {
  min-width: min(500px, 50vw);
  height: 40px;
  font-size: 14px;
  border-radius: 7px;
  padding-inline: 10px;
  outline: none;
  background: rgb(229, 231, 235);
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef)
    drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.invite_modal_body input:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px #242424;
  background-color: transparent;
}

.invite_modal_footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  gap: 5px;
  border-top: 1px solid rgb(229, 231, 235);
  width: 100%;
}

.invite_modal_footer .close_invite {
  font-size: 14px;
  color: rgb(243, 18, 96);
  font-weight: 600;
  border: 1px solid rgb(229, 231, 235);
  background: transparent;
  padding: 8px 16px;
  border-radius: 7px;
}

.error_message {
  font-size: 12px;
  color: rgb(243, 18, 96);
  text-align: right;
  width: 100%;
}

.invite_modal_footer .submit_invite,
.invite_patient_button {
  background: #115dfc;
  border-radius: 7px;
  font-size: 14px;
  padding: 8px 16px;
  color: #ffffff;
  cursor: pointer;
  border: none;
  min-width: 70px;
}

.invite_modal_footer .close_invite:hover {
  background: rgba(243, 18, 96, 0.19);
}

.rotate {
  animation: rotate 1s linear infinite;
}

.my_patient_list_header {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
/* PATIENT REFFERAL */
.invite_modal_subheader,
.modal_doctor_card {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.reffer_modal_doctor_list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.invite_modal_subheader {
  padding: 20px;
  border-bottom: 1px solid rgb(229, 231, 235);
}

.invite_modal_subheader img,
.modal_doctor_card img {
  object-fit: cover;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.modal_doctor_card > div {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.modal_doctor_card h5 {
  font-size: 16px;
  font-weight: 500;
  color: rgb(17, 24, 39);
}

.patient_refer_modal .reffer_modal_doctor_list {
  max-height: 40vh;
  overflow-y: scroll;
}
.reffer_modal_doctor_list,
.reffer_modal_doctor_list * {
  transition: all 0.1s ease;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .invite_modal_body input {
    min-width: 80vw;
  }
}
