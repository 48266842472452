.Imprint-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 3rem;
  font-weight: 700;
  font-family: "Onest", sans-serif;
  background: linear-gradient(to right, #48b98d 15%, #0185cf 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  height: 50vh;
}

.Imprint-text-container span {
  font-size: 3rem;
  font-weight: 700;
  font-family: "Onest", sans-serif;
  background: linear-gradient(to right, #48b98d 15%, #0185cf 100%);
  -webkit-background-clip: text; /* WebKit browsers (Chrome, Safari) */
  background-clip: text;
  color: transparent; /* Set text color to transparent */
  width: fit-content;
  margin-bottom: 32px;
}

.Imprint_text {
  display: flex;
  flex-direction: column;
  gap: 32px !important;
  text-align: center;
}

.Imprint_text h3 {
  font-size: 24px;
  color: rgb(26, 34, 73);
  margin: 0;
  margin-bottom: 10px;
}

.Imprint_text p {
  margin: 0;
  font-size: 20px;
  color: rgb(26, 34, 73);
  font-weight: 400;
}

.Imprint_text a {
  background: linear-gradient(to right, #48b98d 15%, #0185cf 100%);
  -webkit-background-clip: text; /* WebKit browsers (Chrome, Safari) */
  background-clip: text;
  color: transparent; /* Set text color to transparent */
}
