.homepage-icons-container .about-container {
  padding-block: 3rem;
  background-image: linear-gradient(to right, #48b98d 15%, #0185cf 100%);
}

.homepage-icons-container .about-container-inner {
  max-width: 1300px;
  margin: auto;
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4rem;
}

.homepage-icons-container .about-container .item-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage-icons-container .about-container .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 8rem;
}

.homepage-icons-container .about-container h1 {
  font-size: 4rem;
  font-family: "Onest", sans-serif;
  color: white;
  margin: 0;
  font-weight: 700;
}

.homepage-icons-container .about-container h2 {
  font-size: 1.2rem;
  font-weight: 300;
  font-family: "Onest", sans-serif;
  color: white;
  text-align: center;
}

.homepage-icons-container .about-container .logo {
  margin-inline: 1.5rem;
}

.homepage-icons-container .about-container img {
  height: 5rem;
  width: 5rem;
  object-fit: contain;
}

@media (max-width: 768px) {
  .homepage-icons-container .text,
  .homepage-icons-container .images {
    max-width: 100%;
    width: 100%;
    height: fit-content;
  }

  .homepage-icons-container {
    flex-direction: column-reverse;
  }

  .homepage-icons-container .text {
    padding: 1rem 1.5rem;
    align-items: center;
    padding-right: 0rem;
  }

  .homepage-icons-container .images .image-container-02 {
    flex-direction: row;
    height: 10rem;
    width: 100%;
    gap: 0rem;
    transform: none;
  }

  .homepage-icons-container .images .image-container-01 {
    display: none;
  }
  .homepage-icons-container .text h1 {
    text-align: center;
  }

  .homepage-icons-container .text p {
    text-align: center;
  }
  .homepage-icons-container {
    height: fit-content;
  }
  .homepage-icons-container .about-container .logo {
    margin-inline: 0rem;
  }
  .homepage-icons-container .about-container .logo img {
    height: 4rem;
    width: 4rem;
  }
  .homepage-icons-container .about-container-inner {
    gap: 2rem;
  }
  .homepage-icons-container .about-container .text h1 {
    font-size: 2rem;
  }
  .homepage-icons-container .about-container .text h2 {
    font-size: 1rem;
  }
  .homepage-icons-container .about-container .text {
    flex: 1;
  }
}
