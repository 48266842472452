.homepage-header-container {
  background-image: url(../../assets/homepage-new/header.webp);
  background-position: top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 750px;
}

/* font-family: 'Libre Baskerville', serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Onest', sans-serif; */

.homepage-header-container .header-content {
  width: 100%;
  flex-grow: 1;
  overflow-x: clip;
}

.homepage-header-container .container {
  max-width: 1300px;
  width: 100%;
  margin: auto;
  padding: 3rem;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}

.homepage-header-container .text h1 {
  font-size: 3.2rem;
  color: white;
  font-family: "Libre Baskerville", serif;
  text-transform: uppercase;
  font-weight: 700;
}

.homepage-header-container .text h2 {
  font-size: 1.5rem;
  color: #24b394;
  padding-block: 2rem;
  border-bottom: 2px solid #0471af;
  width: fit-content;
  font-family: "Onest", sans-serif;
}

.homepage-header-container .text p {
  font-size: 1.3rem;
  color: white;
  padding-block: 2rem;
  width: 70%;
  font-family: "Onest", sans-serif;
}

.homepage-header-container .text {
  width: 60%;
  padding-block: 3rem;
}

.homepage-header-container .image {
  width: 40%;
  height: 100%;
}

.homepage-header-container .image img {
  width: 45rem;
  height: fit-content;
  object-fit: contain;
  position: absolute;
  right: -40px;
  bottom: -110px;
}

.homepage-header-container .btn-large {
  border: none;
  font-weight: bold;
  color: white;
  padding: 0.7rem 1.2rem;
  border-radius: 2rem;
}

.homepage-header-container .btn-large.gradient-image {
  background-image: linear-gradient(to right, #48b98d 15%, #0185cf 100%);
}

@media (max-width: 1200px) {
  .homepage-header-container .text h1 {
    font-size: 2.3rem;
  }

  .homepage-header-container .image img {
    width: 40rem;
  }
}

@media (max-width: 950px) {
  .homepage-header-container .text h2 {
    font-size: 1.2rem;
    padding-block: 1rem;
  }

  .homepage-header-container .text p {
    font-size: 1.1rem;
    padding-block: 1rem;
  }
  .homepage-header-container .image img {
    width: 33rem;
    right: -70px;
  }
}

@media (max-width: 768px) {
  .homepage-header-container .container {
    flex-direction: column;
    padding: 0rem;
    overflow: hidden;

  }
  .homepage-header-container .image {
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-block: 3rem;
  }
  .homepage-header-container .image img {
    position: static;
    height: 100%;
    width: 100%;
    max-width: 35rem;
  }
  .homepage-header-container .text {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 0;
    max-width: 30rem;
    margin: auto;
    padding: 1.5rem;
    padding-block: 5rem;
  }
  .homepage-header-container .text h1{
    font-size: 2rem;
  }

  .homepage-header-container .text h2 {
    margin: 0;
    border: 0;
    padding-bottom: 0;
  }
  .homepage-header-container .text p {
  font-size: 1rem;
  }
  .homepage-header-container .text h1,
  .homepage-header-container .text h2,
  .homepage-header-container .text p {
    text-align: center;
    width: 100%;
    margin: 0;
  }
  .homepage-header-container .btn-large {
    width: fit-content;
  }
}

@media (max-width: 450px) {
  .homepage-header-container {
    height: fit-content;
  }
  .homepage-header-container .image img {
  width: 25rem;
  }
}
