/* VIDEO FEED COMPONENT STYLES */
.vc_component,
.vc_component_guest {
  border-color: rgba(219, 234, 254, 1);
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

  grid-column: 3 / -1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;

  --box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.vc_component_guest {
  grid-column: 1 / -1;
}

/* WHEN VIDEO IS FOCUSED LAYOUT CHANGE */
.vc_component_focused {
  display: grid;
  row-gap: 30px;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas:
    "tn tn tn tn vcf vcf"
    "vf vf vf vf vcf vcf"
    "vf vf vf vf vcf vcf"
    "vc vc vc vc vcf vcf";
  border-radius: 0;
  background: none;
  box-shadow: none;
  padding: 0;
}

.vc_component_focused .vc_heading {
  border-color: rgba(219, 234, 254, 1);
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

  padding: 20px;
  height: fit-content;
}

.vc_component_focused .vc_controls {
  border-color: rgba(219, 234, 254, 1);
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

  align-self: flex-end;
}

.vc_feed_focused {
  border-color: rgba(219, 234, 254, 1);
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

  position: relative;
  padding: 20px;
  scroll-behavior: smooth;
  grid-area: vcf;
}

.vc_feed_focused .vc_feed {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: calc(100vh - 80px);
  overflow-y: scroll;
}
/* FOCUSED STYLES END */

.vc_feed {
  display: flex;
  gap: 10px;
}

.vc_feed_control_icon {
  position: absolute;
  top: 40%;
  z-index: 10;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: grid;
  place-items: center;
  border-radius: 50%;
}

.vc_feed_control_icon:hover,
.vc_feed_control_icon:focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  transform: scale(1.2);
}

.vc_feed_control_icon.left {
  left: 5px;
}

.vc_feed_control_icon.right {
  right: 5px;
}

.vc_feed::-webkit-scrollbar-thumb,
.vc_feed::-webkit-scrollbar-track {
  display: none;
}

.vc_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-area: tn;
}

.vc_heading h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 39px;
  color: var(--main-text-color);
}

/* SINGLE VIDEO COMPONENTS STYLES */

.svc_wrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;
  background-color: var(--main-bg2-color);
  display: grid;
  place-items: center;
  max-width: 300px;
  min-width: 200px;
}

.vc_feed_focused .svc_wrapper {
  max-width: unset;
}

.svc_container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
}

.svc_mic,
.svc_volume {
  position: absolute;
  inset: auto 10px 20px auto;
  z-index: 10;
}

.svc_volume {
  bottom: 80px;
}

.svc_mic > .svc_control_button {
  pointer-events: none;
}

.single_video_container_user_title {
  background-color: #f4effb;
  border-radius: 16px;
  color: black;
  padding: 5px 10px;
  position: absolute;
  inset: auto auto 20px 10px;
  z-index: 10;
  color: #34d399;
  box-shadow: var(--box-shadow);
}

h3.single_video_container_user_title {
  padding-block: 10px;
  font-size: 12px;
  color: black;
  margin-bottom: 0;
}

.vc_focused .single_video_container_user_title {
  inset: 20px auto auto 10px;
}

.svc_profile_image {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

/* FOCUSED VIDEO COMPONENT */

.vc_focused {
  position: relative;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: calc(100vh - 300px);
  border-radius: 16px;
  overflow: hidden;
  grid-area: vf;
  aspect-ratio: 431/242;
  align-self: center;
  display: grid;
  place-items: center;
  background: var(--main-bg2-color);
}

.vc_focused_video {
  width: 100%;
  height: 100%;
}

.vc_focused_video .svc_mic,
.vc_focused_video .svc_volume {
  box-shadow: var(--box-shadow);
}

.close_vc_focused {
  border: none;
  background: #fecdd3;
  color: #f43f5e;
  box-shadow: var(--box-shadow);
  padding: 7px 20px;
  border-radius: 30px;
  outline: transparent;
  transition: all 0.1s ease;
  margin-left: auto;
  position: absolute;
  inset: auto auto 10px 10px;
  z-index: 1000;
}

/* VIDEO CONTROL STYLES */

.vc_controls {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 30px;
  grid-area: vc;
  height: fit-content;
}

.vc_control_button {
  border: none;
  background: #fafafa;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  padding: 7px 20px;
  border-radius: 30px;
  outline: transparent;
  transition: all 0.1s ease;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.vc_control_button span {
  font-size: 10px;
}

.svc_control_button {
  border: none;
  background: #06b6d4;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  padding: 0;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  color: white;
  border-radius: 30px;
  outline: transparent;
  transition: all 0.1s ease;
}

.vc_control_button:not(.vc_control_leave):hover {
  box-shadow: var(--box-shadow);
}

.vc_control_button:not(.vc_control_leave).active:hover {
  box-shadow: var(--box-shadow);
}

.vc_control_button.vc_control_leave {
  margin-left: auto;
  padding-inline: 2rem;
  background: #fecdd3;
  color: #f43f5e;
  box-shadow: var(--box-shadow);
}

.vc_control_button.active {
  box-shadow: var(--box-shadow);
}

.vc_controls .vc_control_button.active {
  background: #06b6d4;
  color: white;
  box-shadow: (--box-shadow);
}

/* SKELETION LOADER */

.video__feed__loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 2rem;
  height: 90vh;
  grid-column: 3 / -1;
  color: #0284c7;
}

/* VC CONTROL LABEL */

.vc_control_label {
  display: flex;
  align-items: center;
  gap: 3px;
  background: #f4effb;
  padding: 10px 20px;
  transition: all 0.1s ease;
  border-radius: 30px;
}

.vc_control_label * {
  cursor: pointer;
}

.vc_control_label select {
  width: 70px;
  border: 0;
  outline: none;
  background: transparent;
}

/* CHAT TOGGLE BUTTONT STYLES */

.chat_toggle_button {
  display: none !important;
}

@media (max-width: 1200px) {
  .vc_component_focused .vc_heading,
  .vc_feed_parent,
  .vc_controls {
    padding: 10px 20px;
  }

  .chat_toggle_button {
    display: flex !important;
  }

  .vc_component {
    grid-template-columns: -1 / 1;
  }

  .vc_component_focused {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 10px;
  }

  .vc_component_focused .vc_controls {
    position: fixed;
    inset: auto 1rem 1rem 1rem;
    z-index: 100;
  }

  .vc_focused {
    height: 50vh;
  }

  .vc_feed_focused {
    background: transparent;
    box-shadow: none;
    padding: 0;
  }

  .vc_feed_focused .vc_feed {
    flex-direction: row;
    overflow-x: scroll;
    height: fit-content;
  }

  .vc_feed_focused .svc_wrapper {
    max-width: 300px;
  }

  .vc_feed {
    height: 70vh;
  }

  .vc_heading h2 {
    font-size: 16px;
  }

  /* HIDE THE SCREEN SHOT BUTTON ON MOBILE DEVICES */
  .screenshot {
    display: none !important;
  }

  .vc_component {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }

  .vc_heading {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .vc_feed {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
  }
}

@media (max-width: 600px) {
  .vc_controls {
    flex-wrap: wrap;
    justify-content: center;
  }

  .vc_focused {
    height: 40vh;
  }

  .vc_component {
    row-gap: 20px;
  }

  .vc_control_button.vc_control_leave {
    margin: 0;
  }
}
