.homepage-more-buttons-container {
  width: 100%;
  background-image: url(../../assets/homepage-new/bg2.webp);
  background-size: auto;
  background-color: #0185cf;

}

/* font-family: 'Libre Baskerville', serif;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Onest', sans-serif; */

.homepage-more-buttons-container .container {
  max-width: 1300px;
  width: 100%;
  margin: auto;
  padding: 3rem;
  display: flex;
  align-items: center;
  height: 100%;
}

.homepage-more-buttons-container .container-wrapper {
  padding-block: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.homepage-more-buttons-container h1 {
  font-size: 3rem;
  color: rgb(255, 255, 255);
  font-family: "Libre Baskerville", serif;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  max-width: 800px;
  width: 100%;
  margin: auto;
  padding-bottom: 5rem;
}

.homepage-more-buttons-container .button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.homepage-more-buttons-container .btn-large {
  border: none;
  font-weight: bold;
  color: #0185cf;
  padding: 1rem 2rem;
  border-radius: 2rem;
  background-color: white;
}

@media (max-width: 768px) {
  .homepage-more-buttons-container h1 {
    font-size: 2rem;
    padding-bottom: 3rem;
  }
  .homepage-more-buttons-container .container {
    padding: 2rem;
  }
  .homepage-more-buttons-container .button-container {
    flex-direction: column;
  }
}
