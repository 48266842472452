/* .diseases-diagnose-container {
} */

.diseases-diagnose-container .diseases-list-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-left: 5rem;
  gap: 0.5rem;
  margin: 0;
  padding-block: 1rem;
  justify-content: flex-end;

}

.diseases-diagnose-container .diseases-list-container li {
  list-style-type: none;
  padding: 0.3rem 0.7rem;
  border-radius: 5px;
  background-color: #0d6efd;
  color: white;
  font-weight: 500;
}

@media (max-width: 768px) {
  .diseases-diagnose-container .diseases-list-container {
    padding-left: 2rem;
  }
  .diseases-diagnose-container .diseases-list-container li {
 
    font-size: 0.5rem;
  }
  
}
