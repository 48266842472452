.desktop-accordian-container {
  padding: 2rem;
  padding-top: 3rem;
}

.desktop-accordian-container .diseases-tabs-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.7rem;
}

.desktop-accordian-container .capitalized {
  text-transform: capitalize;
  border-radius: 5px;
  background-color: rgba(4, 156, 213, 1);
  color: white;
  font-weight: 500;
  padding: 0.3rem 0.5rem;
  font-size: 0.8rem;
}

.desktop-doctor-info-container .doctor_list_image {
  height: 158px;
  aspect-ratio: 2/2;
  min-width: 158px;
  width: 158px;
}

.desktop-doctor-info-container .doctor-name {
  display: flex;
  gap: 1rem;
}

.desktop-doctor-info-container .availability {
  font-size: 0.8rem;
  font-weight: 400;
  color: white;
  background-color: #0ea5e9;
  padding: 0.3rem 0.8rem;
  border-radius: 1rem;
}

.patient-box1.desktop-doctor-info-container {
  align-items: center;
}
.desktop-doctor-info-container .doctor_list_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.desktop-doctor-info-container .not-available {
  background-color: #64748b;
  color: white;
}

.doctor_calendar_patient {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 1em;
}

@media (max-width: 768px) {
  .desktop-accordian-container {
    padding: 1rem;
    padding-top: 3rem;
  }
  .book-appointment-button-container {
    margin-top: 1.5rem;
  }
  .book-appointment-button-container > div {
    margin: auto;
  }
}

@media (max-width: 576px) {
  .desktop-doctor-info-container .doctor_list_image {
    height: 100px;
    aspect-ratio: 2/2;
    min-width: 100px;
    width: 100px;
  }

  .desktop-accordian-container {
    padding: 0.5rem;
    padding-top: 3rem;
  }

  .doctor-text-container {
    padding-top: 2rem;
  }
}
