@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: Montserrat, sans-serif;
}

code {
  font-family: Montserrat, sans-serif;
}

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input[type="date"] {
  padding: 3px;
  top: 50%;
  left: 50%;
  font-family: "Roboto Mono", monospace;
  color: #798189;
  font-size: 18px;
  /* border: none !important; */
  outline: none;
  border-radius: 5px;
}

::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
}

.selected-date {
  background-color: #0080ff;
}

.selected-date {
  color: #0080ff;
}

.ghost_button {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.no-data-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.no-data-message img {
  width: 400px;
  margin-inline: auto;
}

.availabitlity_select_error {
  display: flex;
  font-size: 12px;
  color: red;
  padding-top: 10px;
}

.doctor_profile_errors {
  font-size: 12px;
  color: red;
  font-weight: 400;
  padding-left: 20px;
}

.text-black {
  color: black;
}

.text-black:hover {
  color: black;
}

.accordion-body.has_image {
  display: flex;
  align-items: stretch;
  gap: 1rem;
  flex-wrap: wrap;
}

.accordion-body img {
  width: 100%;
  max-width: 250px;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}

.app_questions_image img {
  max-height: 200px;
  object-fit: contain;
  border: 1px solid #80808059;
  margin-bottom: 10px;
}