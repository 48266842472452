.live_meeting_container {
  background: linear-gradient(
    90deg,
    rgb(238, 237, 243) 0%,
    0.77381%,
    rgb(239, 238, 244) 1.54762%,
    6.72619%,
    rgb(239, 236, 243) 11.9048%,
    12.381%,
    rgb(240, 237, 244) 12.8571%,
    27.9167%,
    rgb(242, 236, 244) 42.9762%,
    51.9048%,
    rgb(239, 236, 243) 60.8333%,
    61.9643%,
    rgb(238, 235, 246) 63.0952%,
    66.7262%,
    rgb(235, 234, 249) 70.3571%,
    73.2738%,
    rgb(232, 232, 248) 76.1905%,
    77.1429%,
    rgb(230, 231, 248) 78.0952%,
    79.9405%,
    rgb(228, 229, 249) 81.7857%,
    84.1667%,
    rgb(227, 228, 248) 86.5476%,
    87.0238%,
    rgb(226, 227, 248) 87.5%,
    89.3452%,
    rgb(224, 224, 252) 91.1905%,
    95.5952%,
    rgb(220, 223, 252) 100%
  );
  min-height: 100vh;
  max-height: 100vh;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 16px;
}

/* PERMISSION DENIED ERROR */
.invalid_appointment_date {
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.permission__denied {
  grid-column: 3/-1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-inline: 3rem;
  max-width: 500px;
  text-align: center;
  margin-inline: auto;
}

.permission__denied h3 {
  font-size: 2rem;
  font-weight: 500;
  color: #dc2626;
}

.permission__denied button {
  background: #06b6d4;
  color: white;
  box-shadow: (--box-shadow);
  padding: 10px 2rem;
  border: none;
  outline: none;
  border-radius: 30px;
}

/* JOIN AS GUEST FORM */

.join_as_guest {
  position: fixed;
  inset: 0;
  display: grid;
  place-items: center;
}

.join_as_guest_container {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 50px 40px 50px 40px;
  background-color: #ffffff;
  box-shadow: 0px 106px 42px rgba(0, 0, 0, 0.01),
    0px 59px 36px rgba(0, 0, 0, 0.05), 0px 26px 26px rgba(0, 0, 0, 0.09),
    0px 7px 15px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  font-family: "Inter", sans-serif;
  border: 1px solid rgb(229, 231, 235);
}

.join_as_guest_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
}

.join_as_guest_header img {
  margin-bottom: 20px;
}

.title {
  margin: 0;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  color: #212121;
}

.subtitle {
  font-size: 0.725rem;
  max-width: 80%;
  margin-inline: auto;
  margin-top: 20px;
  text-wrap: balance;
  text-align: center;
  line-height: 1.1rem;
  color: #8b8e98;
}

.join_as_guest_body form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.join_as_guest_body label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  font-size: 14px;
  color: #8b8e98;
  font-weight: 600;
}

.join_as_guest_body input {
  min-width: min(500px, 50vw);
  height: 40px;
  font-size: 14px;
  border-radius: 7px;
  padding-inline: 10px;
  outline: none;
  background: rgb(229, 231, 235);
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef)
    drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.join_as_guest_body input:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px #242424;
  background-color: transparent;
}

.join_as_guest_body .submit_invite {
  background: #115dfc;
  border-radius: 7px;
  font-size: 14px;
  padding: 8px 16px;
  color: #ffffff;
  cursor: pointer;
  border: none;
  min-width: 70px;
  margin-top: 40px;
}

@media (max-width: 1200px) {
  .vc_component {
    grid-column: -1 / 1;
  }
}

@media (max-width: 768px) {
  .join_as_guest_body input {
    min-width: 80vw;
  }
}
