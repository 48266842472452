.homepage-how-to-container {
  width: 100%;
  background-image: url(../../assets/homepage-new/bg1.webp);
  background-color: #0185cf;
}

/* font-family: 'Libre Baskerville', serif;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Onest', sans-serif; */

.homepage-how-to-container .container {
  max-width: 1300px;
  width: 100%;
  margin: auto;
  padding: 3rem;
  display: flex;
  align-items: center;
  height: 100%;
}

.homepage-how-to-container .container-wrapper {
  padding-block: 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homepage-how-to-container .container-wrapper > h1.sub {
  font-size: 2.5rem;
  font-weight: 500;
  font-style: italic;
  padding: 0;
}

.homepage-how-to-container .container-wrapper > h1 {
  font-size: 3rem;
  color: white;
  font-family: "Libre Baskerville", serif;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  max-width: 1100px;
  width: 100%;
  margin: auto;
  padding-bottom: 2rem;
}

.homepage-how-to-container .section-01{
  padding-top: 5rem;
}


.homepage-how-to-container .section-01 h1.up {
  font-size: 2.5rem;
  font-weight: 500;
}

.homepage-how-to-container .section-01 h1 {
  font-size: 3rem;
  color: white;
  font-family: "Libre Baskerville", serif;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  max-width: 1000px;
  width: 100%;
  margin: auto;
}

.homepage-how-to-container .section-01 .image-wrapper {
  height: 20rem;
  max-width: 30rem;
  width: 100%;
}

.homepage-how-to-container .section-01 .image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.homepage-how-to-container .section-01 .images-gallery {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3rem;
}

.homepage-how-to-container .section-02 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 10rem;
}

.homepage-how-to-container .section-02 .text {
  flex: 1;
  padding-inline: 7rem;
  position: relative;
}
.homepage-how-to-container .section-02 .text span {
  position: absolute;
  top: -10px;
  left: 40px;
  font-size: 3rem;
  color: white;
  font-family: "Libre Baskerville", serif;
  text-decoration: underline;
}

.homepage-how-to-container .section-02 .text span h1 {
  font-size: 3rem;
  color: white;
  font-family: "Libre Baskerville", serif;
  text-decoration: underline;
}

.homepage-how-to-container .section-02 .text h1 {
  font-size: 2.5rem;
  color: white;
  font-family: "Libre Baskerville", serif;
  text-transform: capitalize;
  font-weight: 500;
  text-align: left;
  max-width: 1000px;
  width: 100%;
  margin: auto;
}

.homepage-how-to-container .section-02 .text p {
  font-size: 1.5rem;
  color: white;
  font-family: "Onest", sans-serif;
  padding-block: 1rem;
}

.homepage-how-to-container .section-02 .image {
  width: 25rem;
  position: relative;
  height: 100%;
  padding: 5rem;
}

.homepage-how-to-container .section-02 .image .screen {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 16rem;
  object-fit: contain;
}

.homepage-how-to-container .section-02 .image .arrow {
  position: absolute;
  bottom: -180px;
  left: -210px;
  width: 10rem;
  object-fit: contain;
}

.homepage-how-to-container .section-03 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 3rem;
}

.homepage-how-to-container .section-03 .text {
  flex: 1;
  padding-inline: 7rem;
  position: relative;
}

.homepage-how-to-container .section-03 .text h1 {
  font-size: 2.5rem;
  color: white;
  font-family: "Libre Baskerville", serif;
  text-transform: capitalize;

  font-weight: 500;
  text-align: left;
  max-width: 1000px;
  width: 100%;
  margin: auto;
}

.homepage-how-to-container .section-03 .text span {
  position: absolute;
  top: -10px;
  left: 40px;
  font-size: 3rem;
  color: white;
  font-family: "Libre Baskerville", serif;
  text-decoration: underline;
}

.homepage-how-to-container .section-03 .text p {
  font-size: 1.5rem;
  color: white;
  padding-block: 1rem;
  font-family: "Onest", sans-serif;
}

.homepage-how-to-container .section-03 .image {
  width: 25rem;
  position: relative;
  height: 100%;
}

.homepage-how-to-container .section-03 .image .screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16rem;
  object-fit: contain;
}

.homepage-how-to-container .section-03 .image .arrow {
  position: absolute;
  bottom: -270px;
  right: -170px;
  width: 10rem;
  object-fit: contain;
  transform: scaleX(-1);
}

.homepage-how-to-container .section-04 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 10rem;
}

.homepage-how-to-container .section-04 .text {
  flex: 1;
  padding-inline: 7rem;
  position: relative;
}

.homepage-how-to-container .section-04 .text h1 {
  font-size: 2.5rem;
  color: white;
  font-family: "Libre Baskerville", serif;
  text-transform: capitalize;
  font-weight: 500;
  text-align: left;
  max-width: 1000px;
  width: 100%;
  margin: auto;
}

.homepage-how-to-container .section-04 .text span {
  position: absolute;
  top: -10px;
  left: 40px;
  font-size: 3rem;
  color: white;
  font-family: "Libre Baskerville", serif;
  text-decoration: underline;
}

.homepage-how-to-container .section-04 .text p {
  font-size: 1.5rem;
  color: white;
  padding-block: 1rem;
  font-family: "Onest", sans-serif;
}

.homepage-how-to-container .section-04 .image {
  width: 25rem;
  position: relative;
  height: 100%;
}

.homepage-how-to-container .section-04 .image img {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 22rem;
  object-fit: contain;
}

.homepage-how-to-container .section-04 .image .screen {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 16rem;
  object-fit: contain;
}

.homepage-how-to-container .section-05 .text {
  display: flex;
  flex-direction: column;
  padding-block: 3rem;
  position: relative;
}

.homepage-how-to-container .section-05 .text h1.up {
  font-size: 2.5rem;
  font-weight: 500;
  font-style: italic;
  padding: 0;
}

.homepage-how-to-container .section-05 .text h1 {
  font-size: 2.5rem;
  color: white;
  font-family: "Libre Baskerville", serif;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  max-width: 1000px;
  width: 100%;
  margin: auto;
}

.homepage-how-to-container .section-05 .button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage-how-to-container .section-05 .btn-large {
  border: none;
  font-weight: bold;
  color: #0471af;
  padding: 1rem 2rem;
  border-radius: 2rem;
  background-color: white;
}

@media (max-width: 1200px) {
  .homepage-how-to-container .section-04 .image .screen,
  .homepage-how-to-container .section-02 .image .screen {
    left: 50%;
  }
  .homepage-how-to-container .section-02 .image .arrow {
    bottom: -180px;
    left: -110px;
  }
  .homepage-how-to-container .section-03 .image .arrow {
    bottom: -320px;
    right: -100px;
    width: 8rem;
  }
}

@media (max-width: 950px) {
  .homepage-how-to-container .section-02 .image .arrow,
  .homepage-how-to-container .section-03 .image .arrow {
    display: none;
  }

  .homepage-how-to-container .section-02 .text h1,
  .homepage-how-to-container .section-03 .text h1,
  .homepage-how-to-container .section-04 .text h1 {
    font-size: 2rem;
  }

  .homepage-how-to-container .section-02 .text p,
  .homepage-how-to-container .section-03 .text p,
  .homepage-how-to-container .section-04 .text p {
    font-size: 1rem;
  }
  .homepage-how-to-container .section-02 .image .screen,
  .homepage-how-to-container .section-03 .image .screen,
  .homepage-how-to-container .section-04 .image .screen {
    width: 13rem;
  }

  .homepage-how-to-container .section-03 .text {
    padding: 0;
  }

  .homepage-how-to-container .section-03 .text span {
    left: -65px;
  }

  .homepage-how-to-container .section-02 .text,
  .homepage-how-to-container .section-03 .text,
  .homepage-how-to-container .section-04 .text {
    padding-right: 0;
  }
  .homepage-how-to-container .section-02,
  .homepage-how-to-container .section-04 {
    padding-block: 7rem;
  }
}

@media (max-width: 768px) {
  .homepage-how-to-container .container {
    padding: 1.5rem;
  }


  .homepage-how-to-container .section-01 .image-wrapper {
    height: 10rem;
  }

  .homepage-how-to-container .section-05 .text h1.up,
  .homepage-how-to-container .container-wrapper > h1.sub,
  .homepage-how-to-container .section-01 h1.up {
    font-size: 1.5rem;
  }

  .homepage-how-to-container .section-05 .text h1,
  .homepage-how-to-container .section-01 h1,
  .homepage-how-to-container .container-wrapper > h1 {
    font-size: 2rem;
  }

  .homepage-how-to-container .section-03 {
    flex-direction: column;
    padding-block: 1rem;
  }

  .homepage-how-to-container .section-02,
  .homepage-how-to-container .section-04 {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0;
  }

  .homepage-how-to-container .section-02 {
    padding-top: 3rem;
  }
  .homepage-how-to-container .section-02 .image .screen,
  .homepage-how-to-container .section-03 .image .screen,
  .homepage-how-to-container .section-04 .image .screen {
    position: static;
    transform: none;
    width: 10rem;
    padding-bottom: 3rem;
  }
  .homepage-how-to-container .section-02 .image,
  .homepage-how-to-container .section-03 .image,
  .homepage-how-to-container .section-04 .image {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 25rem;
    width: 100%;
  }
  .homepage-how-to-container .section-02 .text,
  .homepage-how-to-container .section-03 .text,
  .homepage-how-to-container .section-04 .text {
    padding-right: 1.5rem;
    padding-left: 4rem;
  }

  .homepage-how-to-container .section-02 .text h1,
  .homepage-how-to-container .section-03 .text h1,
  .homepage-how-to-container .section-04 .text h1 {
    font-size: 1.7rem;
  }

  .homepage-how-to-container .section-02 .text span,
  .homepage-how-to-container .section-03 .text span,
  .homepage-how-to-container .section-04 .text span {
    left: 25px;
    top: -5px;
    font-size: 2rem;
  }
}
