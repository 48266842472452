.profile_messages_wrapper {
  display: flex;
  align-items: stretch;
  gap: 10px;
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid rgba(219, 234, 254, 1);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  min-height: calc(100vh - 150px);
  max-height: calc(100vh - 150px);
  position: relative;
}

/* Conversation List Styles */

.conversation_list {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  max-height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.conversation_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 13px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 15px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.conversation_card:hover {
  background-color: #ecf0ff;
}

.conversation_card.active {
  background-color: #ecf0ff;
}

.conversation_card * {
  margin: 0;
  padding: 0;
}

.conversation_info {
  flex: 1;
}

.conversation_card img {
  width: 49px;
  height: 49px;
  object-fit: cover;
  border-radius: 50%;
  align-self: center;
}

.conversation_card h4 {
  font-size: 17px;
  font-weight: 400;
}

.conversation_card p {
  font-size: 14px;
  text-align: right;
}

.conversation_card span {
  font-size: 12px;
}

.conversation_card div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}

/* Conversatin list small Screen Styles */
.conversation_list_small {
  position: absolute;
  transform: translateX(-100%);
  z-index: 100;
  inset-block: 0;
  background: white;
  transition: all 0.3s ease;
}

.conversation_list_small.show {
  transform: translateX(0);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

/* Chat fallback styles */

.profile-message .choose_a_chat {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  gap: 2rem;
}

.profile-message .choose_a_chat .icon {
  font-size: 3rem;
  color: #06b6d4;
}

.chat_loader_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  padding-inline: 10px;
}

.coversation_skeletion {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
}

.coversation_skeletion > div {
  background: #d9e8d8;
  border-radius: 10px;
  height: 50px;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: #d9e8d8;
  }
  100% {
    background-color: #d9e8d8af;
  }
}

.chat_loader_wrapper.chat_feed_loader {
  max-width: unset;
}

.chat_loader_wrapper svg {
  font-size: 1.5rem;
}

/* Chat Feed styles */

.profile_messages_wrapper .cf_container {
  width: 100%;
  border-radius: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.profile_messages_wrapper .cf_body {
  max-height: 100%;
}

.profile_messages_wrapper .cf_input {
  padding-top: 10px;
}

.profile_messages_wrapper .cf_heading .chat_header_image {
  width: 49px;
  height: 49px;
}

/* Search Component styles */

.conversation_list .search__wrapper {
  position: relative;
}

.conversation_list .group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  width: 100%;
}

.conversation_list .input {
  height: 50px;
  line-height: 28px;
  padding: 0 1rem;
  width: 100%;
  padding-left: 2.5rem;
  border: 2px solid transparent;
  outline: none;
  background-color: #d9e8d8;
  color: #0d0c22;
  box-shadow: 0 0 5px #c1d9bf, 0 0 0 10px #f5f5f5eb;
  transition: 0.3s ease;
}

.conversation_list .input::placeholder {
  color: #777;
}

.conversation_list .icon {
  position: absolute;
  left: 1rem;
  fill: #777;
  width: 1rem;
  height: 1rem;
}

.conversation_list .search__wrapper .results {
  position: absolute;
  inset-inline: 0;
  top: 60px;
  z-index: 100;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  background-color: #d9e8d8;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.conversation_list .search__wrapper .results .single_result {
  padding: 10px 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease;
}

.conversation_list .search__wrapper .results .single_result:hover {
  background-color: white;
  cursor: pointer;
}

.conversation_list .search__wrapper .results .single_result img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  background-color: #06b6d4;
}

.conversation_list .search__wrapper .results .single_result .img {
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: #06b6d4;
}

.fallback_profile_image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #06b6d4;
  padding: 10px;
  color: white;
}

.fallback_profile_image svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.conversation_list .search__wrapper .results .single_result h3 {
  font-size: 17px;
  font-weight: 400;
}

/* Chat Feed header toggle conversation button */

.toggle__conversation {
  border: none;
  outline: none;
  box-shadow: none;
  color: #06b6d4;
  font-size: 2rem;
  margin-left: auto;
}

@media (max-width: 1200px) {
  .profile_messages_wrapper {
    min-height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
  }

  .profile_messages_wrapper .cf_container {
    position: static;
    z-index: 0;
  }

  .conversation_card,
  .conversation_list .input {
    padding-right: 15px 5px;
  }
}

@media (max-width: 480px) {
  .conversation_list {
    min-width: 100%;
  }
}
