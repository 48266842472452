.main-section-button {
  border: 1px solid var(--main-button-color);
  background-color: var(--main-button-color);
}

.main-section-button:hover {
  border: 1px solid var(--main-text-color);
  background-color: var(--main-text-color);
}

.doctorprofilemain {
  width: 100px;
  height: 100px;
}

.date-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.active-date {
  background-color: var(--main-button-color);
  color: var(--main-text3-color);
}

.active-date {
  background-color: #62c29a;
}

.active-slot {
  background-color: var(--main-button-color);
  color: var(--main-text3-color);
}

.active-chat {
  background-color: #eaeaea;
  border-radius: 5px;
}

.date {
  font-size: 13px;
}

.day {
  font-size: 14px;
  color: #888;
}

.custom-date-picker {
  width: 200%;
}

.patient-box1 {
  display: flex;
}

@media (max-width: 576px) {
  .patient-box1 {
    display: block;
  }

  .patient-box2 {
    display: none;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat;
}

:root {
  --main-text-color: rgba(14, 38, 71, 1);
  --main-text2-color: #049cd5;
  --main-text3-color: white;
  --main-text4-color: #737373;
  --main-bg-color: rgba(232, 249, 255, 1);
  --main-bg1-color: rgba(98, 194, 154, 0.75);
  --main-bg2-color: rgba(4, 156, 213, 0.21);
  --main-button-color: rgba(4, 156, 213, 1);
  --main-button2-color: #cc5959;
  --main-button3-color: #62c29a;
}

/* Navbar Component Start*/
#navbar-a {
  color: var(--main-text-color);
}

.container-navbar {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.09);
}

#navbar-a:hover {
  color: var(--main-button-color);
}

.main-button {
  color: var(--main-button-color);
  border: 1px solid var(--main-button-color);
  background-color: #f8f9fa;
}

.main-button:hover {
  color: #f8f9fa;
  border: 1px solid var(--main-button-color);
  background-color: var(--main-button-color);
}

/* Navbar Component End*/
.patient-main {
  background-color: var(--main-bg-color);
}

.patient-p-color {
  color: var(--main-text-color);
}

.patient-button {
  background-color: var(--main-button-color);
  border: 1px solid var(--main-button-color);
  height: 47px;
  color: var(--main-text3-color);
}

.patient-input {
  width: 100%;
  height: 35px;
}

.patient-button1 {
  border: 1px solid var(--main-button3-color);
  color: var(--main-button3-color);
  background-color: var(--main-text3-color);
}

.patient-button1:hover {
  border: 1px solid var(--main-button3-color);
  background-color: var(--main-button3-color);
  color: var(--main-text3-color);
}

.logout-btn {
  background-color: var(--main-button-color);
  color: var(--main-text3-color);
}

.logout-btn:hover {
  background-color: var(--main-text-color);
  color: var(--main-text3-color);
}

.new_appointment_notification {
  width: 20px;
  height: 20px;
  font-size: 10px;
  background: linear-gradient(to right, #48b98d 15%, #0185cf 100%);
  color: white;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

@media (max-width: 576px) {
  .patient-p {
    font-size: 13px;
  }

  .patient-p1 {
    font-size: 10px;
  }
}

.patient-button3 {
  background-color: var(--main-text3-color);
  border: 1px solid var(--main-text4-color);
}

.patient-button3:hover {
  background-color: var(--main-text4-color);
  color: var(--main-text3-color);
}

.patient-button2 {
  background-color: var(--main-button-color);
  color: var(--main-text3-color);
  border: 1px solid var(--main-button-color);
}

.patient-button2:hover {
  background-color: var(--main-text3-color);
  color: var(--main-button-color);
}

.dashboard-main {
  background-color: var(--main-bg-color);
}

.dashboard-main3 {
  background-color: var(--main-bg-color);
  border-left: 7px solid var(--main-text-color);
}

.dashboard-p {
  font-size: 15px;
}

.dashboard-p1 {
  font-size: 13px;
}

.dashboard-main2 {
  border-right: 1px solid #e0dcdc;
}

.input-group-append {
  cursor: pointer;
}

.dashboard-button {
  background-color: var(--main-button-color);
  border: 1px solid var(--main-button-color);
  color: var(--main-text3-color);
}

.dashboard-button:hover {
  background-color: var(--main-button1-color);
  color: var(--main-text3-color);
}

.dashboard-button1 {
  background-color: #62c29a;
  border: 1px solid var(--main-bg1-color);
  color: var(--main-text3-color);
}

.dashboard-h5 {
  font-size: 16px;
}

.mo {
  color: var(--main-text-color);
}

.dashboard-p5 {
  font-size: 20px;
}

.dashboard-p3 {
  font-size: 14px;
}

.input-field::placeholder {
  font-size: 15px;
  /* Adjust the font size to your preference */
}

.doctor10-img {
  width: 30%;
}

@media (max-width: 768px) {
  .dashboard-p3 {
    font-size: 12px;
  }

  .doctor10-img {
    width: 38%;
  }

  .dashboard-p5 {
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .dashboard-main2 {
    border-right: none;
  }

  .main-img {
    width: 33%;
  }

  .dashboard-p5 {
    font-size: 15px;
  }

  .dashboard-p1 {
    font-size: 7px;
  }

  .dashboard-p3 {
    font-size: 11px;
  }

  .doctor10-img {
    width: 38%;
  }

  .dashboard-button {
    font-size: 9px;
  }

  .dashboard-h5 {
    font-size: 16px;
  }
}

/* Custom CSS for vertical tabs */
.nav-pills {
  flex-direction: column;
}

.nav-pills .nav-link {
  color: var(--main-text-color);
  text-align: start;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid white;
}

.nav-pills .nav-link:hover {
  color: var(--main-button-color);
  background-color: var(--main-text3-color);
  border: 1px solid var(--main-button-color);
}

.nav-pills .nav-link.active {
  color: var(--main-button-color);
  background-color: var(--main-text3-color);
  border: 1px solid var(--main-button-color);
}

.tab-pt {
  padding: 20px;
  /* Optional: Add some padding to the tab content */
}

.main-input {
  width: 100%;
  height: 35px;
}

.nomo {
  color: var(--main-text-color);
  text-decoration: none;
  font-size: 14px;
}

.nomo:hover {
  padding-bottom: 15px;
  border-bottom: 2px solid var(--main-button-color);
}

.nomo:active {
  color: var(--main-text-color);
  border-bottom: 2px solid var(--main-button-color);
}

.card-box1 {
  background-color: var(--main-text-color);
  height: 35px;
}

.dashboard-p2 {
  color: var(--main-text-color);
}

.card-button {
  border: 1px solid var(--main-button2-color);
  color: var(--main-button2-color);
  background-color: var(--main-text3-color);
}

.card-button:hover {
  border: 1px solid var(--main-button2-color);
  background-color: var(--main-button2-color);
  color: var(--main-text3-color);
}

.card-button1 {
  border: 1px solid var(--main-button3-color);
  color: var(--main-button3-color);
  background-color: var(--main-text3-color);
}

.card-button1:hover {
  border: 1px solid var(--main-button3-color);
  background-color: var(--main-button3-color);
  color: var(--main-text3-color);
}

.card-button3 {
  border: 1px solid #049cd5;
  color: #049cd5;
  background-color: var(--main-text3-color);
}

.card-button3:hover {
  border: 1px solid var(--main-button3-color);
  background-color: #049cd5;
  color: var(--main-text3-color);
}

.card-button2 {
  border: 1px solid var(--main-text4-color);
  color: var(--main-text4-color);
  background-color: var(--main-text3-color);
}

.card-button2:hover {
  border: 1px solid var(--main-text4-color);
  background-color: var(--main-text4-color);
  color: var(--main-text3-color);
}

.dashboard-th {
  font-size: 14px;
}

@media (max-width: 768px) {
  .dashboard-th {
    font-size: 8px;
  }

  .li-tob {
    margin: 2%;
  }
}

@media (max-width: 576px) {
  .dashboard-th {
    font-size: 8px;
  }

  .ul-tob {
    display: block;
  }

  .li-tob {
    margin: 5%;
  }
}

.dashboard-box1 {
  background-color: var(--main-button2-color);
  border: 1px solid var(--main-button2-color);
}

/* Toggle Button */
.switch1 {
  position: relative;
  display: inline-block;
  width: 40px;
  /* Adjust width */
  height: 22px;
  /* Adjust height */
}

.switch1 input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 11px;
  /* Adjust border-radius */
}

.slider1:before {
  position: absolute;
  content: "";
  height: 16px;
  /* Adjust height */
  width: 16px;
  /* Adjust width */
  left: 4px;
  bottom: 3px;
  /* Adjust position */
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider1 {
  background-color: var(--main-text-color);
}

input:focus + .slider1 {
  box-shadow: 0 0 1px var(--main-text-color);
}

input:checked + .slider1:before {
  -webkit-transform: translateX(16px);
  /* Adjust translation value */
  -ms-transform: translateX(16px);
  /* Adjust translation value */
  transform: translateX(16px);
  /* Adjust translation value */
}

.slider1.round {
  border-radius: 22px;
  /* Adjust border-radius */
}

.slider1.round:before {
  border-radius: 50%;
}

.full-page-button {
  width: 100%;
  text-align: left;
  position: relative;
  background-color: var(--main-text5-color);
  border: 1px solid var(--main-text5-color);
}

.button-text {
  display: inline-block;
  padding-right: 30px;
  /* Adjust this value to create space for the dropdown arrow */
}

.dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  /* Adjust this value to position the arrow correctly */
  transform: translateY(-50%);
}

/* --------Desktop---------- */
.desktop-box1 {
  background-color: white;
}

.desktop-li {
  font-weight: 500;
  font-size: 12px;
}

.desktop-p {
  font-weight: 500;
  font-size: 17px;
}

.desktop-div1 {
  line-height: 13px;
}

.desktop-p1 {
  color: var(--main-bg1-color);
}

.desktop-button {
  color: var(--main-text3-color);
  background-color: var(--main-button-color);
  border: 1px solid var(--main-button-color);
}

.desktop-button:hover {
  color: black;
  background-color: var(--main-text3-color);
  border: 1px solid var(--main-button-color);
}

.desktop-button1 {
  color: black;
  background-color: var(--main-text3-color);
  border: 1px solid var(--main-text3-color);
}

.desktop-button1:hover {
  color: var(--main-text3-color);
  background-color: var(--main-button-color);
  border: 1px solid black;
}

.desktop-box2 {
  display: flex;
}

@media (max-width: 768px) {
  .desktop-box2 {
    display: block;
  }
  .desktop-box1 {
    background-color: #f5faff;
  }

  .desktop-li {
    font-weight: 500;
    font-size: 12px;
  }

  .desktop-p {
    font-weight: 500;
    font-size: 17px;
  }

  .desktop-div1 {
    line-height: 20px;
  }

  .desktop-p1 {
    color: var(--main-bg1-color);
  }

  .desktop-button {
    color: var(--main-text3-color);
    background-color: var(--main-button-color);
    border: 1px solid var(--main-button-color);
  }

  .desktop-button:hover {
    color: black;
    background-color: var(--main-text3-color);
    border: 1px solid var(--main-button-color);
  }

  .desktop-button1 {
    color: black;
    background-color: var(--main-text3-color);
    border: 1px solid var(--main-text3-color);
  }

  .desktop-button1:hover {
    color: var(--main-text3-color);
    background-color: var(--main-button-color);
    border: 1px solid black;
  }

  .desktop-box2 {
    display: flex;
  }

  @media (max-width: 768px) {
    .desktop-box2 {
      display: block;
    }

    .desktop-p {
      font-weight: 300;
      font-size: 10px;
    }
  }
}

/* ---------Doctor-List Component------------------ */
.list-button {
  background-color: var(--main-button-color);
  border: 1px solid var(--main-button-color);
  color: var(--main-text3-color);
}

.list-button:hover {
  background-color: var(--main-text-color);
  border: 1px solid var(--main-text-color);
  color: var(--main-text3-color);
}

.list-button1 {
  background-color: var(--main-bg-color);
  border: 1px solid var(--main-bg-color);
  color: var(--main-text-color);
}

.list-button1:hover {
  background-color: var(--main-button-color);
  border: 1px solid var(--main-button-color);
  color: var(--main-text3-color);
}

.list-box {
  display: flex;
}

@media (max-width: 576px) {
  .list-box {
    display: block;
  }
}

@media (max-width: 768px) {
  .main-text1 {
    font-size: 8px;
  }
}

.mo12 {
  font-size: 14px;
}

/* ---------Doctor-Detail Component------------------ */
.doctor-detail {
  width: 90%;
}

.detail-text {
  font-size: 16px;
  line-height: 18px;
}

@media (max-width: 575px) {
  .detail-text {
    font-size: 12px;
    line-height: 18px;
  }
}

.detail-link {
  color: var(--main-button-color);
}

.detail-border {
  border-left: 4px solid var(--main-button-color);
}

.doctor-appointment {
  background-color: var(--main-bg-color);
  border: 1px solid var(--main-bg-color);
}

.portal-box1 {
  background-color: rgb(131 199 225);
}

.portal-box {
  margin-top: 35%;
}

@media (max-width: 576px) {
  .portal-box {
    margin-top: 15%;
  }
}

.profile-picture {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#upload-button {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-profile {
  background-color: var(--main-button-color);
}

.progress {
  height: 10px;
  border-radius: 20px;
}

.register-input1 {
  padding-bottom: 165px;
  padding-top: 20px;
}

.progress-bar {
  background-color: #007bff;
  /* Default progress bar color */
}

.progress-complete {
  background-color: var(--main-bg1-color);
  /* Green color for completed progress */
}

.profile-box {
  border-radius: 20px;
}

.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%;
    height: 100vh;
    */
}

.circle {
  width: 40px;
  height: 40px;
  border: 1px solid var(--main-bg-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.dim-content {
  background-color: #f5f5f5;
  pointer-events: none;
  opacity: 37%;
}

.box-navbar2 {
  padding-left: 300px;
}

/* --------------Register------------- */
.register-span {
  color: var(--main-text2-color);
}

.register-input {
  height: 35px;
}

.register-button {
  color: var(--main-button-color);
  border: 1px solid var(--main-button-color);
  background-color: white;
}

.register-button:hover {
  color: white;
  border: 1px solid var(--main-button-color);
  background-color: var(--main-button-color);
}

.register-navbar {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.09);
}

.register-button2 {
  height: 40px;
  background-color: var(--main-button-color);
  border: 1px solid var(--main-button-color);
  color: var(--main-text3-color);
  font-weight: 500;
}

.register-button2:hover {
  color: white;
  background-color: var(--main-text-color);
  border: 1px solid;
  font-weight: 500;
}

.register-box1 {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .register-box1 {
    display: block;
  }
}

.register-logo {
  width: 77%;
}

.register-logo1 {
  width: 50%;
}

@media (max-width: 991px) {
  .register-logo {
    width: 60%;
  }
}

.register-button1 {
  background-color: var(--main-button-color);
  border: 1px solid var(--main-button-color);
}

.register-button1:hover {
  background-color: var(--main-bg2-color);
  border: 1px solid var(--main-button-color);
}

.register-button3 {
  background-color: var(--main-text4-color);
  border: 1px solid var(--main-text3-color);
  color: var(--main-text3-color);
  font-weight: 500;
}

.register-button3:hover {
  color: black;
  font-weight: 500;
}

.register-back {
  border: 1px solid var(--main-text4-color);
}

.register-back:hover {
  border: 1px solid var(--main-text4-color);
  background-color: var(--main-text4-color);
  color: white;
}

.register-next {
  border: 1px solid var(--main-button-color);
  background-color: var(--main-button-color);
  color: white;
}

.register-next:hover {
  border: 1px solid var(--main-button-color);
  background-color: var(--main-text-color);
}

.register3 {
  width: 90%;
}

.icon-show {
  color: var(--main-button-color);
  left: 86%;
  top: 10px;
  font-size: 20px;
}

.dropdown-container {
  width: 100%;
  position: relative;
}

.dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  cursor: pointer;
}

.left-text,
.right-text {
  flex: 1;
}

.dropdown-icon {
  padding: 5px;
}

/* Adjust the position and styles for the dropdown menu */
.dropdown-menu {
  position: relative;
  top: 100%;
  /* Position the dropdown menu below the header */
  left: 0;
  width: 100%;
  background-color: var(--main-text5-color);
  border: 1px solid var(--main-text5-color);
  display: flex;
  flex-direction: column;
}

.dashboard-p4 {
  font-size: 12px;
}

.doc-lab {
  font-size: 13px;
}

@media (max-width: 576px) {
  .doc-lab {
    font-size: 7px;
  }
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}

.modal-dialog {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

/* ------------Routing---------   */
.nav-style {
  color: var(--main-text-color);
  /* Default text color for navigation links */
  padding: 5px 10px;
  /* Add padding for spacing */
  display: block;
  font-size: 15px;
  text-decoration: none;
  border: 1px solid white;
}

.nav-style:hover {
  color: var(--main-button-color);
  /* Change text color for better contrast */
  border: 1px solid var(--main-button-color);
  border-radius: 5px;
  font-size: 15px;
  text-decoration: none;
  padding: 5px 10px;
}

/* Styling for the active navigation link */
.active-css {
  /* Change background color for active link */
  color: var(--main-button-color);
  /* Change text color for better contrast */
  border: 1px solid var(--main-button-color);
  border-radius: 5px;
  font-size: 15px;
  text-decoration: none;
  padding: 5px 10px;
}

@media (max-width: 576px) {
  .nav-style {
    color: var(--main-text-color);
    /* Default text color for navigation links */
    padding: 5px 10px;
    /* Add padding for spacing */
    display: block;
    font-size: 12px;
    text-decoration: none;
    border: 1px solid white;
  }

  .nav-style:hover {
    color: var(--main-button-color);
    /* Change text color for better contrast */
    border: 1px solid var(--main-button-color);
    border-radius: 5px;
    font-size: 12px;
    text-decoration: none;
    padding: 5px 10px;
  }

  /* Styling for the active navigation link */
  .active-css {
    /* Change background color for active link */
    color: var(--main-button-color);
    /* Change text color for better contrast */
    border: 1px solid var(--main-button-color);
    border-radius: 5px;
    font-size: 12px;
    text-decoration: none;
    padding: 5px 10px;
  }

  .doctordetialname {
    font-size: 16px !important;
  }

  .appointmetimg {
    width: 48% !important;
  }
}

/* tabs.css */
.nav-tabs {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid white;
}

.nav-tabs li {
  margin-right: 20px;
}

.nav-tabs a {
  text-decoration: none;
  color: var(--main-text-color);
  font-weight: light;
  border-bottom: 2px solid transparent;
}

.nav-tabs a:hover {
  border-bottom-color: var(--main-button-color);
}

.nav-tabs a.active {
  border-bottom-color: var(--main-button-color);
  font-weight: bold;
}

.nav-style1 {
  color: var(--main-text-color);
  /* Default text color for navigation links */
  padding: 5px 10px;
  /* Add padding for spacing */
  display: block;
  font-size: 15px;
  text-decoration: none;
  border-bottom-color: var(--main-button-color);
}

.nav-style1:hover {
  color: var(--main-button-color);
  /* Change text color for better contrast */
  border-bottom-color: var(--main-button-color);
  font-size: 15px;
  text-decoration: none;
  padding: 5px 10px;
}

/* Styling for the active navigation link */
.active-css1 {
  /* Change background color for active link */
  color: var(--main-button-color);
  /* Change text color for better contrast */
  border-bottom-color: var(--main-button-color);
  font-size: 15px;
  text-decoration: none;
  padding: 5px 10px;
}

.main-input55::placeholder {
  font-size: 0px;
  /* Adjust the font size as needed */
}

/* Additional styling for the input field */
.main-input55 {
  width: 200px;
  /* Set the width as needed */
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-p {
  font-size: 12px;
}

.btn-Upc {
  font-size: 12px;
}

.dashboard-th1 {
  font-size: 11px;
}

.box-button34 {
  background-color: white;
  border: 1px solid var(--main-button-color);
  color: var(--main-button-color);
}

.box-button34:hover {
  border: 1px solid var(--main-button-color);
  background-color: var(--main-button-color);
  color: white;
}

.mo8 {
  color: var(--main-text2-color);
}

.mo7 {
  font-size: 13px;
}

.mo9 {
  font-size: 14px;
}

.logi-style {
  color: var(--main-button-color);
}

.logi-style {
  color: var(--main-button-color);
}

.po-1 {
  font-size: 13px;
}

.po-1 {
  font-size: 13px;
}

.po-2 {
  font-size: 15px;
  line-height: 15px;
}

.link-login {
  color: var(--main-text-color);
}

.link-login:hover {
  color: var(--main-button-color);
}

/* -------Spinner------------ */
.content-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 4px solid var(--main-text2-color);
  border-top: 4px solid var(--main-button3-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.por-p {
  font-size: 17px;
}

.por-p1 {
  font-size: 30px;
  width: 67%;
}

.por-p2 {
  font-size: 15px;
  width: 67%;
}

@media (max-width: 1024px) {
  .por-p {
    font-size: 14px;
  }

  .por-p1 {
    font-size: 30px;
    width: 78%;
  }

  .por-p2 {
    width: 78%;
  }
}

@media (max-width: 768px) {
  .por-p1 {
    font-size: 23px;
    width: 81%;
  }

  .por-p2 {
    font-size: 13px;
    width: 81%;
  }
}

@media (max-width: 768px) {
  .por-p1 {
    width: 100%;
  }

  .por-p2 {
    width: 100%;
  }
}

.doctor-detail {
  width: 15%;
  border-radius: 10px;
}

.doctordetialname {
  font-size: 36px;
}

.paradoctordetail {
  font-size: 18px;
  font-weight: 500;
}

.dcotprdetaolhead {
  font-size: 32px;
  font-weight: 600;
}

.appointmetimg {
  border-radius: 10px !important;
}

.patient-buttond {
  background: #049cd5;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: none;
}

.patient-buttond:hover {
  background: #049cd5;
  color: #fff;
}

.main-text48 {
  font-size: 20px;
}

@media (max-width: 576px) {
  .main-text48 {
    font-size: 17px;
  }
}

/* ----------------Availability Time Picker Library-------------------------- */
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 150px !important;
  border: 1px solid #ced4da !important;
}

.react-datepicker__input-container input {
  border: 1px solid #ced4da !important;
  padding-left: 10px;
  padding-top: 5px;
  width: 150px !important;
  padding-bottom: 5px;
  border-radius: 5px;
}

.react-datepicker {
  width: 150px !important;
}

.react-datepicker__time-container {
  width: 150px !important;
  border: 1px solid #ced4da !important;
}

.react-datepicker {
  border: none !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  display: none !important;
}

.react-datepicker--time-only .react-datepicker__time-container {
  margin-top: -4% !important;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  display: none !important;
}

/* ----------------Availability Time Picker Library-------------------------- */
/* ----------------Collapse Button-------------------------- */

@media (min-width: 768px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

/* ----------------Collapse Button-------------------------- */
.join-now-button {
  background-color: #cc5959;
  color: #fff;
  border: none;
  text-decoration: none;
}

.custom-cursor {
  cursor: pointer;
}

.date-item {
  background-color: #f8f8f8;
  width: 50px;
  margin: 10px;
  text-align: center;
  color: #000;
}

@media (max-width: 576px) {
  .date-item {
    background-color: #f8f8f8;
    width: 50px;
    margin: 3px;
    text-align: center;
    color: #000;
  }
}

.active-date {
  background-color: var(--main-button-color);
  color: var(--main-text3-color);
}

.appointmentbox {
  width: 46px;
  height: 46px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 10px;
}

.schdule {
  width: 46px;
  height: 46px;
}

.pendingupcoming {
  color: #049cd5;
  font-family: "Montserrat";
}

.upcoming {
  color: rgb(203, 228, 9);
  font-family: "Montserrat";
}

.completed {
  color: #62c29a;
  font-family: "Montserrat";
}

.badge-doctor-routes {
  background-color: var(--main-button-color);
}

/* ---------Buy Product------- */
.product-p1 {
  font-size: 14px;
  font-weight: 700;
  /* margin-bottom: 8px; */
}

@media (max-width: 768px) {
  .product-p1 {
    font-size: 11px;
    font-weight: 700;
  }
}

@media (max-width: 576px) {
  .product-p1 {
    font-size: 13px;
    font-weight: 700;
  }
}

.product-p2 {
  font-size: 15px;
  font-weight: 700;
}

@media (max-width: 576px) {
  .product-p2 {
    font-size: 11px;
    font-weight: 700;
  }
}

.product-p3 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .product-p3 {
    font-size: 10px;
    line-height: 16px;
  }
}

@media (max-width: 576px) {
  .product-p3 {
    font-size: 11px;
    line-height: 16px;
  }
}

.product-p4 {
  font-size: 22px;
  font-weight: 700;
}

/* Apply hover effect to add black background with low opacity */
.image-container54:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust opacity as needed */
  z-index: 1;
}

/* Style for centered button */
.overlay54 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 2;
}

.image-container54:hover .overlay54 {
  display: block;
}

.check-btn {
  background-color: var(--main-button-color);
  border: 1px solid var(--main-button-color);
  color: white;
}

.check-btn:hover {
  background-color: white;
  border: 1px solid var(--main-button-color);
  color: var(--main-button-color);
}

/* --------Gender-Dropdown-Arrow----- */
.custom-select55 {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-select55 select {
  padding-right: 30px;
}

.select-icon55 {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.select-icon55 i {
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

/* --------Gender-Dropdown-Arrow----- */

/* Style the date input */
.custom-date-input55 {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Style the placeholder text */
.custom-date-input55::placeholder {
  /* Add your custom placeholder styles here */
  color: #212529;
  font-size: 30px;
  font-style: italic;
}

/* -------------9-Oct-2023------------------ */
.portal-p-1 {
  font-size: 15px;
}

@media (max-width: 768px) {
  .portal-p-1 {
    font-size: 9px;
  }
}

@media (max-width: 576px) {
  .portal-p-1 {
    font-size: 14px;
  }
}

.portal-p-2 {
  font-size: 19px;
}

@media (max-width: 768px) {
  .portal-p-2 {
    font-size: 13px;
  }
}

@media (max-width: 576px) {
  .portal-p-2 {
    font-size: 15px;
  }
}

.dashboard-icon-img {
  width: 25%;
}

@media (max-width: 1024px) {
  .dashboard-icon-img {
    width: 30%;
  }
}

@media (max-width: 768px) {
  .dashboard-icon-img {
    width: 30%;
  }
}

@media (max-width: 576px) {
  .dashboard-icon-img {
    width: 23%;
  }
}

/* Add these CSS rules to your stylesheet */

/* Ensure mono1 div remains fixed */
.mono1 {
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: auto;
}

/* Allow mono2 and mono3 to scroll if content exceeds their height */
.mono3 {
  max-height: calc(97vh - 60px);
  overflow-y: auto;
}

.mono2 {
  max-height: calc(70vh - 60px);
  overflow-y: auto;
}

.pro-set-h4 {
  font-size: 18px;
}

/* -------11-10-23---------------- */

.multiselect-container::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  /* Adjust this value as needed */
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  /* Adjust the color as needed */
  cursor: pointer;
}

.react-tagsinput {
  border-radius: 4px !important;
  margin-top: 3px !important;
  border: 1px solid #ced4da !important;
}

.react-tagsinput-input {
  height: 20px !important;
}

@media (max-width: 576px) {
  .dash-hr {
    display: none;
  }
}

.pati-block {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .pati-block {
    display: block;
  }
}

@media (max-width: 576px) {
  .pati-block {
    display: flex;
    justify-content: space-between;
  }
}

.breadcrumb-container {
  font-size: 0.8rem;
}

.breadcrumb-container a {
  text-transform: capitalize;
}

.breadcrumb-container a:last-child {
  color: #888;
  pointer-events: none;
}

.breadcrumb-container span {
  padding-inline: 0.5rem;
}

.breadcrumb-container > a:last-child span {
  display: none;
}

.breadcrumb-wrapper-patient {
  padding: 1rem;
  padding-top: 0;
}

.breadcrumb-wrapper-doctor {
  padding: 1rem;
  padding-top: 0;
  padding-left: 0;
}

.accepted_reschedule {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  justify-content: flex-start;
}

.accepted_reschedule button {
  width: 110px;
}

p.main-text {
  display: flex;
  align-items: center;
  gap: 10px;
}

.doctor_list_image {
  width: 200px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  overflow: hidden;
}

.doctor_list_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

/* FIND A DOCTOR */

.find_a_doctor_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.find_a_doctor.Button {
  padding: 5px 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease;
}

.find_a_doctor.Button:hover,
.find_a_doctor.Button:focus {
  padding: 5px 1rem;
}

.find_doctor_nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px !important;
}

.find_doctor_nav *:not(button) {
  padding: 0;
  margin: 0;
}

/* Guest List styles */

.guest_cards_container {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 20px;
}

.grid_cards_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  flex-wrap: wrap;
}

.guest_cards_container p {
  margin-bottom: 0 !important;
  font-weight: 500;
}

.guest_card {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 10px;
  color: white;
  font-size: 12px;
  background-color: #115bfc;
  padding: 5px 10px;
  border-radius: 5px;
}

.guest_card .invited_by_img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  object-position: center;
  background-color: #333;
  color: white;
  border-radius: 50px;
}

@media (max-width: 768px) {
  .find_a_doctor_container {
    margin: 0;
    font-size: 12px;
  }

  .guest_cards_container {
    grid-template-columns: 1fr;
  }

  .grid_cards_wrapper {
    justify-content: flex-start;
  }
}
