/* 


contacti-usstyles


*/

.contact-us-header {
  min-height: fit-content;
  display: flex;
  flex-direction: column;
}

/* font-family: 'Libre Baskerville', serif;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Onest', sans-serif; */

.contact-us-header .form-wrapper .btn-small {
  background-color: transparent;
  border: none;
  font-weight: bold;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.9rem;
  width: fit-content;
  margin-left: auto;
  width: 11rem;
}

.contact-us-header .form-wrapper .btn-small.color-gradient {
  background-image: linear-gradient(
    to right,
    rgba(72, 185, 142, 1) 15%,
    rgba(1, 132, 207, 1) 100%
  );
}

.contact-us-header .form-bg {
  background-image: url(../assets/bg1.jpg);
  background-position: center;
  background-size: cover;
  width: 100%;
  flex: 1;
}

.contact-us-header .overlay > h1 {
  max-width: 40rem;
  padding: 5rem 1.5rem;
  margin: auto;
  text-align: center;
  font-weight: 700;
  font-family: "Libre Baskerville", serif;
  color: white;
}

.contact-us-header .overlay {
  background-image: linear-gradient(
    to top,
    rgba(72, 185, 142, 0.7) 15%,
    rgba(1, 132, 207, 0.7) 100%
  );
  width: 100%;
  height: 100%;
}

.contact-us-header .form-wrapper-outer {
  width: 100%;
  height: 100%;
  padding-inline: 1.5rem;
  background-image: linear-gradient(to bottom, transparent 50%, white 50%);
}

.contact-us-header .form-wrapper {
  max-width: 30rem;
  background-color: #f4f4f4;
  margin: auto;
  padding: 3rem;
  height: 100%;
  width: 100%;
}

.contact-us-header .form-wrapper > h1 {
  text-align: center;
  font-size: 1.5rem;
  font-family: "Libre Baskerville", serif;
  text-decoration: underline;
  padding-bottom: 5px;
  padding-inline: 10px;
  width: fit-content;
  margin: auto;
  border-bottom: 2px solid black;
  margin-bottom: 1.5rem;
}

.contact-us-header .form-label {
  font-family: "Onest", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.contact-us-header p {
  margin: 0;
}

.contact-us-header .form-input {
  background-color: #f4f4f4;
  border-radius: 0;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 1px black;
  padding: 0.5rem;
  padding-block: 6px;
  font-size: 0.8rem;
}

.contact-us-header .form-input.larger {
  height: 8rem;
}

.contact-us-header .form-input svg {
  fill: red;
}

.contact-us-header .phone-input {
  padding: 0;
}

.contact-us-header .gender {
  height: 34px;
}

.contact-us-header .phone-input .form-control {
  background-color: transparent;
  border: none;
  outline: none;
}

.contact-us-header .phone-input .form-control:focus {
  box-shadow: none;
}

.contact-us-header .phone-input .flag-dropdown {
  border: none;
  outline: none;
  box-shadow: none;
}

.contact-us-header .form-wrapper .buttons-container {
  display: flex;
  gap: 2rem;
  margin: auto;
  justify-content: center;
  padding-top: 1.5rem;
  border-top: 1px solid rgb(212, 212, 212);
}

.contact-us-header .form-wrapper .buttons-container span,
.contact-us-header .form-wrapper .buttons-container button {
  font-size: 14px;
  border: none;
  font-family: "Onest", sans-serif;
  font-weight: 500;
}

.contact-us-header .form-wrapper .buttons-container button {
  border-bottom: 1px solid black;
}

@media (max-width: 768px) {
  .con-usctis-header .form-wrapper {
    margin-right: 0rem;
    margin: auto;
  }
  .con-usctis-header .form-wrapper .buttons-container span,
  .con-usctis-header .buttons-container button {
    font-size: 12px;
  }
  .con-usctis-header .form-wrapper .buttons-container {
    gap: 1rem;
  }
}

/* 


contact us info


*/

.contact-us-info {
  padding: 5rem 1.5rem;
  padding-top: 8rem;

  margin: auto;
}

.contact-us-info .contact-us-info-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.contact-us-info .contact-us-info-inner .email {
  height: 20rem;
}

.contact-us-info .contact-us-info-inner .email,
.contact-us-info .contact-us-info-inner .phone {
  padding: 2rem;
  flex: 1;
  border: 1px solid rgb(1, 132, 207);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 2/1.5;
  position: relative;
  max-width: 375px;
  width: 100%;
  height: 100%;
  padding-top: 4rem;
}

.contact-us-info .contact-us-info-inner .email img,
.contact-us-info .contact-us-info-inner .phone img {
  position: absolute;
  top: -3rem;
  left: 50%;
  transform: translateX(-50%);
  height: 6rem;
  width: 6rem;
  object-fit: contain;
  object-position: center;
  background-color: white;
  padding: 1rem;
}

.contact-us-info .contact-us-info-inner .email h1,
.contact-us-info .contact-us-info-inner .phone h1 {
  font-size: 1rem;
  font-family: "Onest", sans-serif;
  margin-bottom: 0.5rem;
}
.contact-us-info .contact-us-info-inner .email h2,
.contact-us-info .contact-us-info-inner .phone h2 {
  font-size: 0.8rem;
  font-size: 1rem;
  font-weight: 700;
  font-family: "Onest", sans-serif;
  margin-bottom: 1.5rem;
}

.contact-us-info .contact-us-info-inner .phone h2 {
  font-size: 1.8rem;
}

@media (max-width: 950px) {
  .contact-us-info .contact-us-info-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7rem;
    flex-wrap: wrap;
    flex-direction: column;
  }
}
