.cal_section {
  display: grid;
}

/*  */

.cal_section_title {
  display: flex;
  margin-block: 20px 30px;
}

.cal_section_title h1 {
  font-size: 32px;
  font-weight: 500;
  color: rgb(2, 8, 23);
}

.cal_section_title h2 {
  font-size: 24px;
  font-weight: 500;
  color: rgb(2, 8, 23);
}

/*  */

.cal_section_header {
  display: flex;
  align-items: stretch;
  gap: 16px;
  max-width: 100%;
}

.cal_section_header > div {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  gap: 16px;
  flex: 1;
}

.cal_section_header button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  background: transparent;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.cal_section_header button p {
  display: flex;
  align-items: center;
  gap: 5px;
}

.cal_section_header button.active {
  background-color: var(--main-button-color);
}

.cal_section_header button:focus {
  outline: transparent;
}

.cal_section_header button.disabled {
  color: hsl(215.4 16.3% 46.9%);
}

.cal_section_header p {
  padding: 0;
  margin: 0;
  line-height: 1em;
  display: flex;
  flex-direction: column;
}

.cal_day {
  font-size: 12px;
  color: hsl(215.4 16.3% 46.9%);
}

.cal_date {
  font-size: 24px;
  font-weight: 500;
  color: rgb(2, 8, 23);
}

.cal_section_header button.active .cal_day,
.cal_section_header button.active .cal_date {
  color: white;
}

/* CALENDAR SECTION BODY */

.cal_section_body {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-block: 40px;
}

.cal_slot_box {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  padding: 24px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  transition: all 0.1s ease;
  cursor: pointer;
  height: 100%;
}

.cal_slot_wrapper h3 {
  font-size: 16px;
  font-weight: 600;
  color: rgb(2, 8, 23);
  position: relative;
}

.cal_slot_wrapper p {
  font-size: 16px;
  color: rgb(2, 8, 23);
  margin-block: 5px 15px;
}

.cal_slot_box > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cal_slot_box > div img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50px;
}

.cal_slot_box h5 {
  font-size: 14px;
  color: rgb(2, 8, 23);
  margin: 0;
}

.cal_slot_wrapper {
  position: relative;
}

.cal_slot_wrapper input {
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: 0;
  transition: all 0.1s ease;
}

.cal_slot_wrapper input:checked {
  opacity: 1;
}

.calendar_body_loader {
  width: 100%;
  height: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no_slots {
  width: 100%;
  text-align: center;
}

.ghost_link {
  color: rgb(2, 8, 23);
  text-decoration: none;
}

.cal_section_for_patient {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.cal_section_for_patient img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.cal_section_for_patient div {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

/* For expired State */
.cal_slot_wrapper.expired .cal_slot_box {
  border: 1px solid #64748b5c;
  background: #fed7aa;
}

/* For Requested State */

.cal_slot_wrapper.ongoing:has(input:checked) .cal_slot_box {
  border: 2px solid var(--main-button-color);
}

.cal_slot_wrapper.ongoing .cal_slot_box {
  border: 2px solid transparent;
  background-color: #fef08a;
}

.cal_slot_wrapper.ongoing input {
  accent-color: var(--main-button-color);
}

/* For Completed State */

.cal_slot_wrapper.completed:has(input:checked) .cal_slot_box {
  border: 2px solid var(--main-button-color);
}

.cal_slot_wrapper.completed .cal_slot_box {
  border: 2px solid transparent;
  background-color: #99f6e4;
}

.cal_slot_wrapper.completed input {
  accent-color: var(--main-button-color);
}

/* For Requested State */

.cal_slot_wrapper.requested:has(input:checked) .cal_slot_box {
  border: 2px solid var(--main-button-color);
}

.cal_slot_wrapper.requested .cal_slot_box {
  border: 2px solid transparent;
  background-color: #c2fbd7;
}

.cal_slot_wrapper.requested input {
  accent-color: var(--main-button-color);
}

/* For Booked State */

.cal_slot_wrapper.booked:has(input:checked) .cal_slot_box {
  border: 2px solid #16a34a;
}

.cal_slot_wrapper.booked .cal_slot_box {
  border: 2px solid transparent;
  background-color: #86efac;
}

.cal_slot_wrapper.booked input {
  accent-color: #16a34a;
}

/* For Vacant State */

.cal_slot_wrapper.vacant:has(input:checked) .cal_slot_box {
  border: 2px solid #64748b;
}

.cal_slot_wrapper.vacant .cal_slot_box {
  border: 2px solid transparent;
  background-color: #e2e8f0;
}

.cal_slot_wrapper.vacant input {
  accent-color: #64748b;
}

/* For Blocked State */

.cal_slot_wrapper.blocked:has(input:checked) .cal_slot_box {
  border: 2px solid #f43f5e;
}

.cal_slot_wrapper.blocked .cal_slot_box {
  border: 2px solid transparent;
  background-color: #fecaca;
}

.cal_slot_wrapper.blocked input {
  accent-color: #f43f5e;
}

.disable_slot_checkbox {
  pointer-events: none;
}

/*  Appointment in Calendare */

.appoitnment_in_calendar_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cal_slot_icons {
  color: #2563eb;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
}

.cal_slot_icons.expired_icons {
  color: #f43f5e;
}

@media (max-width: 1200px) {
  .cal_section_body {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1200px) {
  .cal_section_body {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .cal_section_for_patient {
    flex-direction: row;
    align-items: center;
  }

  .cal_section_for_patient > div {
    align-items: center;
  }

  .cal_section_title h1 {
    font-size: 24px;
  }

  .cal_section_title h2,
  .cal_date {
    font-size: 16px;
  }

  .cal_day {
    font-size: 10px;
  }

  .cal_section_header,
  .cal_section_header > div {
    gap: 10px;
  }

  .cal_nav {
    grid-row: span 2;
  }

  .cal_section_body {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

@media (max-width: 450px) {
  .cal_section_header > div {
    grid-template-columns: repeat(4, 20%);
    grid-template-rows: 1fr 1fr;
  }

  .cal_section_header .cal_nav {
    padding-inline: 5px;
  }
}
