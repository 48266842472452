.homepage-navbar-container {
  height: 8rem;
  display: flex;
}

.homepage-navbar-container .logo-container {
  padding: 2rem;
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
  background-color: white;
}

.homepage-navbar-container .over-the-nav {
  display: flex;
  padding: 0.5rem 10rem;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
  background-image: linear-gradient(to right, #48b98d 15%, #0185cf 100%);
  height: 40%;
}

.homepage-navbar-container .social-container {
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-right: 1px solid #036197;
  padding-block: 0.5rem;
  height: 100%;
}

.homepage-navbar-container .social-container span {
  background: rgb(26, 34, 73);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: white;
  font-size: 24px;
}

.homepage-navbar-container .navbar-wrapper {
  width: 100%;
}

.homepage-navbar-container .email {
  color: white;
  font-size: 0.8rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #036197;
  padding-block: 0.5rem;
}
.homepage-navbar-container .email span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage-navbar-container .phone {
  font-size: 0.9rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #036197;
  padding-block: 0.5rem;
  width: 100%;
}

.homepage-navbar-container .navbar {
  box-shadow: none;
  height: 60%;
  /* background-color: rgba(3, 97, 151, 0.5); */
  padding: 0;
}

.homepage-navbar-container .navbar-inner {
  padding: 1rem 7rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: linear-gradient(
    to right,
    rgba(72, 185, 142, 0.7) 15%,
    rgba(1, 132, 207, 0.7) 100%
  );
}

.homepage-navbar-container .login-buttons {
  display: flex;
}

.homepage-navbar-container img {
  width: 13rem;
  object-fit: contain;
}

.homepage-navbar-container .social-logo {
  height: 1.7rem;
  width: fit-content;
}

.homepage-navbar-container .email-phone-logo {
  height: 1.2rem;
  width: fit-content;
  margin-right: 0.5rem;
}

.homepage-navbar-container .username {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage-navbar-container .site-links {
  display: flex;
  gap: 5rem;
}

.homepage-navbar-container .site-links div {
  font-weight: bold;
  color: white;
}

.homepage-navbar-container .login-buttons {
  gap: 1rem;
}

.homepage-navbar-container .btn-small {
  background-color: transparent;
  border: none;
  font-weight: bold;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.9rem;
  background-color: pink;
}

.homepage-navbar-container .btn-small.color-p2 {
  background-color: #24b394;
}
.homepage-navbar-container .btn-small.color-gradient {
  background-image: linear-gradient(to right, #48b98d 15%, #0185cf 100%);
}
.homepage-navbar-container .btn-small.color-p1 {
  background-color: #0185cf;
}
.homepage-navbar-container .btn-small {
  background-color: transparent;
  border: none;
  font-weight: bold;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.9rem;
  background-color: red;
}

.homepage-navbar-container .btn-small.color-p2 {
  background-color: #24b394;
}

.homepage-navbar-container .btn-small.color-p1 {
  background-color: #0185cf;
}
@media (max-width: 1200px) {
  .homepage-navbar-container .over-the-nav {
    padding-inline: 2rem;
  }
  .homepage-navbar-container .navbar-inner {
    padding-inline: 2rem;
  }
  .homepage-navbar-container .site-links {
    gap: 2rem;
  }
}

@media (max-width: 950px) {
  .homepage-navbar-container .logo-container {
    padding-inline: 1rem;
  }
  .homepage-navbar-container .over-the-nav {
    padding-inline: 1rem;
  }

  .homepage-navbar-container .social-container {
    gap: 0.5rem;
    width: 50%;
  }

  .homepage-navbar-container .logo-container img {
    width: 8rem;
  }

  .homepage-navbar-container .navbar-inner {
    padding-inline: 1rem;
  }

  .homepage-navbar-container .navbar-inner .site-links {
    gap: 1.5rem;
  }

  .homepage-navbar-container .navbar-inner .site-links div {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .homepage-navbar-container-small {
    height: 3.5rem;
    /* padding: 2rem 1.5rem; */
    display: flex;
    position: relative;
    /* overflow-y: hidden; */
    z-index: 20;
  }

  .homepage-navbar-container-small .logo-container {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .homepage-navbar-container-small .logo-container img {
    height: 2.5rem;
  }

  .homepage-navbar-container-small .buttons-container {
    flex: 1;
    height: 100%;
    background-image: linear-gradient(
      to right,
      rgba(72, 185, 142, 0.7) 15%,
      rgba(1, 132, 207, 0.7) 100%
    );
    display: flex;
    align-items: center;
  }
  .homepage-navbar-container-small .buttons-container .menu-icon {
    margin-left: auto;
    margin-right: 2rem;
    font-size: 2rem;
    color: white;
  }
  .homepage-navbar-container-small .menu-small {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 56px;
    transition: all 0.5s ease-in;
    right: 0;
    z-index: 10;
    background-color: white;
  }

  .homepage-navbar-container-small .menu-small .nav-buttons-container,
  .homepage-navbar-container-small .menu-small .join-buttons-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    margin: 1.5rem;
  }
  .homepage-navbar-container-small .menu-small .join-buttons-container {
    border-top: 1px solid rgb(207, 207, 207);
    margin-top: 0;
    padding-top: 1.5rem;
  }
  .homepage-navbar-container-small .menu-small .nav-buttons-container button {
    background-color: transparent;
    width: fit-content;
    border: transparent;
    outline: transparent;
    font-family: "Onest", sans-serif;
  }
  .homepage-navbar-container-small .btn-small {
    border: none;
    font-weight: bold;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    font-size: 0.9rem;
    background-color: red;
    font-family: "Onest", sans-serif;
  }

  .homepage-navbar-container-small .hide {
    display: none;
  }

  .homepage-navbar-container-small .btn-small.color-p2 {
    background-color: #24b394;
  }

  .homepage-navbar-container-small .btn-small.color-p1 {
    background-color: #0185cf;
  }
}

.border-highlight {
  border-bottom: 2px solid white;
}

.homepage-navbar-container .username .profile-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  position: relative;
}

.homepage-navbar-container .username .profile-container span {
  text-transform: capitalize;
  color: white;
  font-weight: 500;
  font-family: "Onest", sans-serif;
  cursor: pointer;
}

.homepage-navbar-container .username .profile-container img {
  height: 2rem;
  width: 2rem;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
}

.homepage-navbar-container .username .profile-container .icon {
  color: white;
  cursor: pointer;
}

.homepage-navbar-container
  .username
  .profile-container
  .logout-button-container {
  position: absolute;
  background-color: white;
  bottom: -122px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 1rem;
  padding-inline: 2rem;
  flex-direction: column;
  align-items: center;
}

.homepage-navbar-container
  .username
  .profile-container
  .logout-button-container
  button {
  margin-block: 0.5rem;
  font-family: "Onest", sans-serif;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid black;
  padding-inline: 1rem;
}
