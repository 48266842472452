.appointment_questionare {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
}

.reason_question_wrapper {
  text-align: center;
  padding: 3rem 2rem;
  margin: 10px;
  border-radius: 0.25rem;
  border: 1px solid #dee2e6;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  width: 100%;
  min-height: 50%;
}

.reason_question_wrapper h2 {
  font-size: 24px;
  color: rgba(14, 38, 71, 1);
  font-weight: 600;
}

.reason_question_wrapper p {
  font-size: 14px;
  color: rgba(4, 156, 213, 1);
}

.questions_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  text-align: left;
  margin-top: 3rem;
}

.questions_container h5 {
  grid-column: span 2;
  font-weight: bold;
  font-size: 1rem;
  color: #313245;
}

.questions_container > img {
  border: 1px solid #34d399;
  grid-column: span 2;
  min-height: 200px;
  max-height: 300px;
  margin-inline: auto;
  border-radius: 5px;
  object-fit: cover;
  object-position: center center;
}

.questions_container button {
  grid-column: span 2;
  background-color: var(--main-button-color);
  border: 1px solid var(--main-button-color);
  color: var(--main-text3-color);
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  padding: 8px 3rem;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  grid-area: 4px;
}

.questions_container button svg {
  font-size: 1.5rem;
}

/* From uiverse.io by @alexruix */
.questions_container .input {
  line-height: 28px;
  border: 2px solid transparent;
  border-bottom-color: #777;
  padding: 0.2rem 0;
  outline: none;
  background-color: transparent;
  color: #0d0c22;
  transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  grid-column: span 2;
}

.questions_container .input:focus,
.questions_container input:hover {
  outline: none;
  border-bottom-color: #7a9cc6;
}

.questions_container .input::placeholder {
  color: #777;
}

.questions_container .input:focus::placeholder {
  opacity: 0;
  transition: opacity 0.3s;
}

.questions_container .single_reason {
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  font-weight: 500;
  line-height: 1.3em;
  color: #262626;
  background: #e2e8f0;
  cursor: pointer;
  border-radius: 5px;
}

.questions_container .single_reason > div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.questions_container .single_reason img {
  border-radius: 5px;
  margin-bottom: 1rem;
  border: 1px solid #34d399;
  height: 200px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: center center;
}

.questions_container .single_reason.selected {
  background: #34d399;
  color: white;
}

.appointment_questions_loader {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
}

@media (max-width: 768px) {
  .questions_container {
    display: flex;
    flex-direction: column;
  }

  .questions_container > img {
    min-height: 150px;
  }

  .questions_container .single_reason img {
    min-height: 100px;
  }
}
