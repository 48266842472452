.devicebtn{
    width: 100%;
    height: 54px;
    border-radius: 8px;
    background: #049CD5;
    color: #fff;
    border: none;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
}

