/* box */
.welcome__modal.box {
  position: fixed;
  inset: 0;
  z-index: 9999;
  background: #0000008c;
  display: grid;
  place-items: center;

  --light: hsl(220, 50%, 90%);
  --primary: hsl(255, 30%, 55%);
  --focus: hsl(210, 90%, 50%);
  --border-color: hsla(0, 0%, 100%, 0.2);
  --global-background: hsl(220, 25%, 10%);
  --background: linear-gradient(
    to right,
    hsl(210, 30%, 20%),
    hsl(255, 30%, 25%)
  );
  --shadow-1: hsla(236, 50%, 50%, 0.3);
  --shadow-2: hsla(236, 50%, 50%, 0.4);

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Open Sans",
    sans-serif;
}

/* modal */
.welcome__modal .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  height: fit-content;

  /* --m-background is set as inline style */
  background: var(--m-background);
}

.welcome__modal .modal {
  width: 80%;
  max-width: 600px;
  padding: 4rem 2rem;
  border-radius: 0.8rem;

  color: var(--light);
  background-image: linear-gradient(to right, #48b98d 15%, #0185cf 100%);
  box-shadow: var(--m-shadow, 0.4rem 0.4rem 10.2rem 0.2rem) var(--shadow-1);
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome__modal .modal__title {
  font-size: 1.5rem;
  text-align: center;
  display: flex;
}

.welcome__modal .modal__text {
  padding: 0 4rem;
  margin-top: 4rem;
  font-size: 1rem;
  line-height: 2;
  text-align: center;
}

.welcome__modal .modal__btn {
  margin-top: 4rem;
  font-weight: 500;
  padding: 1rem 1.6rem;
  border: 1px solid white;
  border-radius: 100rem;

  background: white;
  color: #0ea5e9;
  font-size: 1rem;
  font-family: inherit;
  letter-spacing: 0.2rem;

  transition: 0.2s;
  cursor: pointer;
}

.welcome__modal .modal__btn:nth-of-type(1) {
  margin-right: 1rem;
}

.welcome__modal .modal__btn:hover,
.welcome__modal .modal__btn:focus {
  transform: translateY(-0.2rem);
}

.welcome__modal button.modal__btn.close__btn {
  padding: 0;
  width: 40px;
  height: 40px;
  position: absolute;
  inset: 10px 10px auto auto;
  margin: 0;
  background: white;
  color: #0ea5e9;
  display: grid;
  place-items: center;
}

@media (max-width: 768px) {
  .welcome__modal .modal__text {
    padding: 0;
    font-size: 14px;
  }
}
