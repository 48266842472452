.under_processing {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;

  width: 100%;
  height: 90vh;
  padding: 20px;
}

.under_processing > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.under_processing p {
    max-width: 500px;
    margin-inline: auto;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}
