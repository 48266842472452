.react-tagsinput {
  display: flex;
}

.gpt_quesion_section h1,
.gpt_quesion_section p {
  max-width: 700px;
  margin-inline: auto;
}

.react-tagsinput {
  display: flex;
  color: #262626;
  background: #e2e8f0;
  border-radius: 5px !important;
  border: none !important;
  padding: 1rem;
}

.react-tagsinput-tag {
  background: #34d399;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 30px;
}

.gpt_questionare {
  margin-top: 64px;
}

.gpt_questionare button {
  grid-column: span 2;
  background-color: rgba(4, 156, 213, 1);
  border: 1px solid rgba(4, 156, 213, 1);
  color: var(--main-text3-color);
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  padding: 8px 3rem;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  grid-area: 4px;
}

.gpt_quesion_section .content-spinner {
  height: 30vh;
}


.react-tagsinput-input {
  width: 115px !important;
}