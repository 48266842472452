.about_page_header {
  --clr_blue: #092658;
  --clr_black: #101010;
  --clr_white: #fff;
}

/* ABOUT PAGE HEADER STYLES */

.about_page_header .text {
  gap: 2rem;
}

.about_page_header .text h1,
.homepage-gallery-container.about_page_gallery h1 {
  color: var(--clr_blue, #092658) !important;
  font-family: "Libre Baskerville", serif !important;
  font-size: 44px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.5px;
  line-height: 1.4em;
  word-wrap: break-word;
}

.about_page_header .text h3 {
  color: var(--clr_blue);
  font-family: "Libre Baskerville", serif;
  font-size: 31px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.4em;
}

.about_page_header .text p,
.about_page_gallery p {
  color: var(--clr_black) !important;
  font-family: "Onest", sans-serif !important;
  font-size: 24px !important;
}

/* ABOUT PAGE GALLERY */

.about_page_gallery .images-container img {
  object-position: center !important;
}

.about_page_gallery p {
  text-align: center;
  margin-top: 2.5rem;
}

/* about page gallery text section */

.homepage-gallery-container.about_page_gallery
  .gallery-text-section
  .text
  span {
  font-size: 30px;
  display: block;
  margin-bottom: 1rem;
}

.homepage-gallery-container.about_page_gallery .gallery-text-section .text p {
  margin-top: 1rem !important;
  font-size: 24px;
  text-align: left;
}

/* ABOUT CHOOSE US SECTION */

.about_choose_us {
  background-image: url(../assets/homepage-new/bg1.webp);
  background-color: #0185cf;
}

.about_choose_us .about_inner {
  max-width: 1300px;
  padding: 128px 64px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: 64px;
  color: var(--clr_white, #fff);
}

.about_choose_us .about_choose_us_header {
  display: flex;
  flex-direction: column;
  gap: 32px;
  text-align: center;
  max-width: 900px;
  margin-inline: auto;
}

.about_choose_us .about_choose_us_header span {
  font-size: 23px;
}

.about_choose_us .about_choose_us_header h1 {
  font-family: "Libre Baskerville", serif !important;
  font-size: 44px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.5px;
  line-height: 1.4em;
  word-wrap: break-word;
}

.about_choose_us_reasons.about_choose_us_reasons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  row-gap: 48px;
}

.about_choose_us_item {
  padding: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.about_choose_us_item h3 {
  font-size: 40px;
  font-family: "Onest", sans-serif !important;
  letter-spacing: 2px;
}

.about_choose_us_item p {
  font-size: 24px;
  font-family: "Onest", sans-serif !important;
  font-weight: 200;
  margin: 0;
}

/* ABOUT CALL TO ACGTIONS */

.about_call_to_action {
  background-image: linear-gradient(90deg, #47b98b 0%, #0085cd 100%);
}

.about_cta_inner {
  max-width: 1300px;
  padding: 128px 64px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: 64px;
  color: var(--clr_white, #fff);
}

.about_call_to_action h1 {
  font-family: "Libre Baskerville", serif !important;
  font-size: 44px !important;
  text-transform: uppercase !important;
  letter-spacing: 1.5px;
  line-height: 1.4em;
  word-wrap: break-word;
  text-align: center;
}

.about_cta_buttons_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.about_cta_buttons_container button {
  border: none;
  font-weight: bold;
  color: #0185cf;
  padding: 1rem 2rem;
  border-radius: 2rem;
  background-color: white;
}

@media screen and (max-width: 768px) {
  .about_page_header .text h1,
  .about_choose_us .about_choose_us_header h1,
  .homepage-gallery-container.about_page_gallery h1,
  .about_page_gallery h1,
  .about_call_to_action h1 {
    text-align: left !important;
    font-size: 30px !important;
    padding: 0;
  }

  .about_call_to_action h1 {
    text-align: center !important;
  }

  .about_page_header .text h3,
  .about_choose_us_item h3 {
    font-size: 24px !important;
  }

  .about_page_header .text p,
  .about_page_gallery p,
  .about_choose_us .about_choose_us_header span,
  .about_choose_us_item,
  .about_choose_us_item p {
    font-size: 18px !important;
    text-align: left !important;
  }

  .about_choose_us .about_inner,
  .about_cta_inner {
    padding: 64px 24px;
  }

  .about_choose_us_reasons.about_choose_us_reasons {
    grid-template-columns: 1fr;
  }
}
