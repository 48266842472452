.homepage-app-container {
  width: 100%;
}

/* font-family: 'Libre Baskerville', serif;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Onest', sans-serif; */

.homepage-app-container .container {
  max-width: 1300px;
  width: 100%;
  margin: auto;
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* height: 100%; */
  padding-bottom: 0;
}

/* .homepage-app-container .container-wrapper {
    padding-block: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  } */

.homepage-app-container h1 {
  font-size: 2rem;
  color: rgb(26, 34, 73);
  font-family: "Libre Baskerville", serif;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  max-width: 1000px;
  width: 100%;
  margin: auto;
  padding: 1rem;
}

.homepage-app-container h2 {
  font-size: 3rem;
  color: rgb(26, 34, 73);
  font-family: "Libre Baskerville", serif;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  max-width: 1000px;
  width: 100%;
  margin: auto;
}

.homepage-app-container .screens-container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.homepage-app-container .screens-container .image-wrapper-2 {
  height: 40rem;
  width: fit-content;
  position: relative;
  z-index: 10;
}
.homepage-app-container .screens-container .image-wrapper-1.image-wrapper-1,
.homepage-app-container .screens-container .image-wrapper-3.image-wrapper-3 {
  height: 37rem;
  width: fit-content;
}

.homepage-app-container .screens-container .image-wrapper-1.image-wrapper-1 {
  transform: translateX(2rem) rotate(-3deg);
}
.homepage-app-container .screens-container .image-wrapper-3.image-wrapper-3 {
  transform: translateX(-2rem) rotate(3deg);
}

.homepage-app-container .screens-container {
  display: flex;
  padding-block: 3rem;
  align-items: flex-end;
}

.homepage-app-container .app-store {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.homepage-app-container .app-store .image-wrapper-4 {
  width: 15rem;
}

.homepage-app-container .app-store img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.partnership-heading {
  font-size: 3rem;
  color: rgb(26, 34, 73);
  font-family: "Libre Baskerville", serif;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  max-width: 1000px;
  width: 100%;
  margin: auto;
  padding: 1rem;
  padding-top: 5rem;
}

@media (max-width: 768px) {
  .homepage-app-container .container {
    padding: 1.5rem;
  }

  .homepage-app-container h1 {
    font-size: 1.7rem;
    color: rgb(26, 34, 73);
    font-family: "Libre Baskerville", serif;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    max-width: 1000px;
    width: 100%;
    margin: auto;
    padding: 1rem;
  }

  .homepage-app-container h2 {
    font-size: 2rem;
    color: rgb(26, 34, 73);
    font-family: "Libre Baskerville", serif;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    max-width: 1000px;
    width: 100%;
    margin: auto;
  }

  .homepage-app-container .screens-container .image-wrapper-2 {
    height: fit-content;
  }
  .homepage-app-container .screens-container .image-wrapper-1.image-wrapper-1,
  .homepage-app-container .screens-container .image-wrapper-3.image-wrapper-3 {
    height: fit-content;
    /* transform: scale(0.9); */
  }

  .homepage-app-container .screens-container .image-wrapper-1.image-wrapper-1 {
    transform: translateX(2rem) translateY(0.7rem) rotate(-3deg) scale(0.9);
  }
  .homepage-app-container .screens-container .image-wrapper-3.image-wrapper-3 {
    transform: translateX(-2rem) translateY(0.7rem) rotate(3deg) scale(0.9);
  }

  .homepage-app-container .app-store .image-wrapper-4 {
    width: 8rem;
  }
  .partnership-heading {
    font-size: 2rem;
  }
}
