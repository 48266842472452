.not_found {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.not_found .btn{
 text-transform: uppercase;
 text-decoration: none;
 color: #10b981;
 padding: 10px 30px;
 border: 1px solid;
 border-radius: 1000px;
 display: inline-block;
 transition: all .2s;
 position: relative;
}

.not_found .btn:hover {
 transform: translateY(-5px);
 box-shadow: 0 10px 20px #10b981;
}

.not_found .btn:active {
 transform: translateY(-3px);
}

.not_found .btn::after {
 content: "";
 display: inline-block;
 height: 100%;
 width: 100%;
 border-radius: 100px;
 top: 0;
 left: 0;
 position: absolute;
 z-index: -1;
 transition: all .3s;
}

.not_found .btn:hover::after {
 background-color: rgb(0, 238, 255);
 transform: scaleX(1.4) scaleY(1.5);
 opacity: 0;
}

@media (max-width: 720px) {
    .not_found video {
        width: 90%;
        max-width: 500px;
    }
}