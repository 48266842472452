/* Navbar Component Start*/
#navbar-a {
    color: var(--main-text-color);
}

.navbar {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.09);
}

#navbar-a:hover {
    color: var(--main-button-color);
}

/* Navbar Component End*/
/* Main Section Component Start*/
.main-page {
    background-color: var(--main-bg-color);
}

.mainsection {
    background: var(--main-bg-color);
}

.mainsection-col {
    padding-top: 70px;
}

.mainsection-button {
    color: var(--main-text3-color);
    background-color: var(--main-button-color);
    border: 1px solid var(--main-button-color);
}

.mainsection-button:hover {
    color: var(--main-text-color);
    border: 1px solid var(--main-button-color);
    background-color: var(--main-text3-color);
}

.mainsection-h2 {
    margin: 0 0 10px 0;
    font-size: 30px;
    font-weight: 700;
    line-height: 39px;
    color: var(--main-text-color);
}

.mainsection-img {
    max-width: 58%;
    margin-left: 36%;
}

.mainsection-p2 {
    color: var(--main-text-color);
    margin-bottom: 50px;
    font-size: 15px;
    line-height: 23px;
}

.mainsection-p {
    width: 58%;
}

.mainsection-h1 {
    width: 65%;
}

.mainsection-input {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    height: 54px;
    align-items: stretch;
    width: 70%;
}

.mainsection-box {
    display: flex;
}

.navo {
    margin-left: 24%;
}

@media (max-width: 1024px) {
    .mainsection-img {
        max-width: 75%;
    }
}

@media (max-width: 991px) {
    .mainsection-p {
        width: 100%;
    }

    .mainsection-h1 {
        width: 100%;
    }

    .mainsection-img {
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    .navo {
        margin-left: 4%;
    }

    .mainsection-p {
        width: 100%;
    }

    .mainsection-h1 {
        width: 100%;
    }

    .mainsection-img {
        max-width: 100%;
    }

    #hero h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    .mainsection-col {
        padding-top: 20px;
    }
}

@media (max-width: 576px) {
    .mainsection-img {
        max-width: 58%;
        margin-left: 1%;
    }

    .navo {
        margin-left: 4%;
    }

    .mainsection-p {
        width: 100%;
    }

    .mainsection-h1 {
        width: 100%;
    }

    .mainsection-box {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-right: 5%;
    }

    .mainsection-col {
        padding-top: 20px;
    }

    .mainsection-h2 {
        margin: 0 0 10px 0;
        font-size: 25px;
        font-weight: 700;
        line-height: 35px;
        color: var(--main-text-color);
    }

    .mainsection-img {
        max-width: 60%;
    }
}

/* Main Section Component End*/
/* HElp Section Component Start*/
.help-box1 {
    background-color: var(--main-bg1-color);
}

.help-box2 {
    background-color: var(--main-bg2-color);
}

.help-h5 {
    font-size: 15px;
}

/* HElp Section Component End*/
/* Appointment Section Component Start*/
.appo-p {
    color: var(--main-text2-color);
    font-size: 14px;
    font-weight: 600;
}

.appo-h3 {
    color: var(--main-text-color);
    font-size: 30px;
    line-height: 42px;
    width: 90%;
    font-weight: 600;
    margin-top: -2%;
}

.appo-li {
    font-size: 15px;
}

.appo-ul {
    line-height: 38px;
}

@media (max-width: 768px) {
    .appo-h3 {
        color: var(--main-text-color);
        font-size: 20px;
        line-height: 25px;
        width: 100%;
        font-weight: 600;
        margin-top: -2%;
    }

    .appo-li {
        font-size: 8px;
    }

    .appo-ul {
        line-height: 25px;
    }
}

@media (max-width: 575px) {
    .appo-h3 {
        color: var(--main-text-color);
        font-size: 26px;
        line-height: 32px;
        width: 100%;
        font-weight: 600;
        margin-top: -2%;
    }

    .appo-li {
        font-size: 8px;
    }

    .appo-ul {
        line-height: 25px;
    }
}

/* Appointment Section Component End*/
/* Banner Section Component Start*/
.main-banner {
    background-color: var(--main-bg-color);
}

.banner-p {
    font-size: 15px;
    font-weight: 400;
    width: 70%;
}

@media (max-width: 575px) {
    .banner-p {
        font-size: 12px;
        font-weight: 400;
        width: 100%;
    }
}

/* Banner Section Component End*/
/* Client Section Component Start*/
.slide img {
    width: 170px;
    height: auto;
    animation: scroll 60s linear infinite;
}

.slide-track {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 3em;
    overflow: hidden;
}

.slider {
    padding: 6em 0em;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translatex(-1000%)
    }
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    width: 70%;
    height: 70%;
}

@media (max-width: 575px) {
    .slide img {
        width: 130px;
        height: auto;
        animation: scroll 60s linear infinite;
    }
}

/* Client Section Component End*/
/* Contact Section Component Start*/
.contact-main {
    background-color: var(--main-button-color);
}

.contact-h2 {
    font-size: 34px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: var(--main-text3-color);
}

.contact-p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: var(--main-text3-color);
}

.contact-button {
    color: var(--main-text3-color);
    border: 1px solid var(--main-bg1-color);
    background-color: var(--main-bg1-color);
}

.contact-button:hover {
    color: white;
    border: 1px solid var(--main-bg1-color);
    background-color: rgba(99, 233, 177, 0.75);
}

.contact-input {
    padding-top: 12px;
    padding-bottom: 12px;
}

@media (max-width: 768px) {
    .contact-h2 {
        font-size: 30px;
        font-weight: 700;
        line-height: 57px;
        letter-spacing: 0.20000000298023224px;
        text-align: left;
        color: var(--main-text3-color);
    }

    .contact-p {
        font-size: 10px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.20000000298023224px;
        text-align: left;
        color: var(--main-text3-color);
    }
}

@media (max-width: 575px) {
    .contact-h2 {
        font-size: 29px;
        font-weight: 700;
        line-height: 57px;
        letter-spacing: 0.20000000298023224px;
        text-align: left;
        color: var(--main-text3-color);
    }

    .contact-p {
        font-size: 10px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.20000000298023224px;
        text-align: left;
        color: var(--main-text3-color);
    }
}

/* Contact Section Component End*/
/* Footer Section Component Start*/
.footer-h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    color: var(--main-text-color);
}

.footer-p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: var(--main-text4-color);
}

/* Footer Section Component End*/

@media (max-width: 575px) {
    .dash-p3 {
        font-size: 10px;
    }
}

.main-img9 {
    width: 100%;
}

@media (max-width: 575px) {
    .main-img9 {
        width: 70%;
    }
}

/* ------------------------------------- */
.pro1 {
    font-size: 29px;
    font-weight: 800;
}

@media (max-width: 768px) {
    .pro1 {
        font-size: 27px;
        font-weight: 800;
    }
}

@media (max-width: 575px) {
    .pro1 {
        font-size: 19px;
        font-weight: 800;
    }
}

/* -----------Invoice------------------ */
.invoice-p1 {
    font-weight: 500;
    font-size: 16px;
}

.invoice-p2 {
    font-weight: 600;
    font-size: 21px;
}

.invoice-p3 {
    font-size: 21px;
    font-weight: 500;

}

.invoice-p4 {
    font-size: 17px;
}

.invoice-th {
    font-size: 14px;
}

.invoice-li {
    font-size: 14px;
}

@media (max-width: 768px) {
    .invoice-p1 {
        font-weight: 500;
        font-size: 13px;
    }

    .invoice-p2 {
        font-weight: 600;
        font-size: 19px;
    }

    .invoice-p3 {
        font-size: 19px;
        font-weight: 300;

    }

    .invoice-p4 {
        font-size: 14px;
    }

    .invoice-th {
        font-size: 13px;
    }

    .invoice-li {
        font-size: 10px;
    }
}

@media (max-width: 575px) {
    .invoice-p1 {
        font-weight: 500;
        font-size: 13px;
    }

    .invoice-p2 {
        font-weight: 600;
        font-size: 18px;
    }

    .invoice-p3 {
        font-size: 18px;
        font-weight: 300;
    }

    .invoice-p4 {
        font-size: 11px;
    }

    .invoice-th {
        font-size: 9px;
    }

    .invoice-li {
        font-size: 10px;
    }
}


/* -----------Subscription------------------ */
.container3 {
    position: relative;
    width: 100%;
    min-height: 85vh;
    /* Adjust as needed */
    overflow: hidden;
}

.background-image3 {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.box-subscription-p {
    text-align: center;
    top: 5%;
    left: 14%;
    position: absolute;
}

.box-subscription-box {
    top: 24%;
    position: absolute;
    width: 100%;
}

.box-subscription-p1 {
    font-size: 14px;
    padding-top: 5px;
    text-align: start;
}

.box-subscription-p2 {
    font-size: 25px;
    text-align: start;
    padding-top: 5px;
}

.box-subscription-box1 {
    position: absolute;
    top: 45%;
}

.invoice-p9 {
    font-size: 20px;
    font-weight: 700;
}

.invoice-p10 {
    font-size: 14px;
    font-weight: 400;
}

@media (max-width: 768px) {
    .box-subscription-box {
        top: 20%;
        position: absolute;
        width: 100%;
    }

    .box-subscription-box1 {
        position: absolute;
        top: 40%;
    }
}

@media (max-width: 576px) {
    .box-subscription-box {
        top: 20%;
        position: absolute;
        width: 100%;
    }

    .box-subscription-box1 {
        position: absolute;
        top: 40%;
    }
}

/* -----------Preview Button------------------ */
.previe-btn {
    color: var(--main-button-color);
    text-decoration: underline;
    cursor: pointer;
}

.previe-btn:hover {
    color: var(--main-text-color);
    text-decoration: underline;
    cursor: pointer;

}


.homepage-icons-container .about-container .logo > svg {
    width: 80px;
    height: 80px;
    color: white;
}