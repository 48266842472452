.homepage-gallery-container {
  width: 100%;
}

/* font-family: 'Libre Baskerville', serif;
  font-family: 'Montserrat', sans-serif;
  font-family: 'Onest', sans-serif; */

.homepage-gallery-container .container {
  max-width: 1300px;
  width: 100%;
  margin: auto;
  padding: 3rem;
  display: flex;
  align-items: center;
  height: 100%;
}

.homepage-gallery-container .container-wrapper {
  padding-block: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homepage-gallery-container h1 {
  font-size: 3rem;
  color: #1a2249;
  font-family: "Libre Baskerville", serif;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  max-width: 1000px;
  width: 100%;
  margin: auto;
}

.homepage-gallery-container .images-container {
  padding-top: 10rem;
  padding-bottom: 5rem;
  display: flex;
}

.homepage-gallery-container .images-container img {
  object-fit: cover;
  aspect-ratio: 2/2.5;
  object-position: right;
  width: 100%;
}

.homepage-gallery-container .images-container .image-wrapper-1 {
  max-width: 23rem;
  width: 100%;
  transform: translateX(-50px);
  min-width: 15rem;
  transform: translateX(50px);
}

.homepage-gallery-container .images-container .image-wrapper-2 {
  max-width: 23rem;
  width: 100%;
  transform: translateX(-50px);
  min-width: 15rem;
  transform: translateY(-100px);
  position: relative;
  z-index: 10;
}

.homepage-gallery-container .images-container .image-wrapper-3 {
  max-width: 20rem;
  width: 100%;
  transform: translateX(-50px);
  min-width: 15rem;
}

.homepage-gallery-container .gallery-text-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
}
.homepage-gallery-container .gallery-text-section .image {
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
  position: relative;
}

.homepage-gallery-container .gallery-text-section .text {
  padding-right: 8rem;
}

.homepage-gallery-container .gallery-text-section .text.less {
  padding-right: 6rem;
}
.homepage-gallery-container .gallery-text-section .text.right {
  padding-right: 0rem;
  padding-left: 8rem;
}

.homepage-gallery-container .gallery-text-section .text.less h1 {
  max-width: 80%;
  margin: 0;
}
.homepage-gallery-container .gallery-text-section .text h1 {
  text-align: left;
  padding-bottom: 2rem;
}

.homepage-gallery-container .gallery-text-section .text p {
  font-family: "Onest", sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  color: #1a2249;
  max-width: 90%;
}

.homepage-gallery-container .gallery-text-section .image img {
  width: 30rem;
  height: 30rem;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-5px, 10px);
}

.homepage-gallery-container .gallery-text-section .image .image-bg {
  width: 30rem;
  height: 30rem;
  background-color: rgb(218, 218, 218);
  border-radius: 50%;
}

.homepage-gallery-container .button-container {
  padding-top: 3rem;
}

.homepage-gallery-container .btn-large {
  font-size: 1.4rem;
  border: none;
  font-weight: bold;
  color: white;
  padding: 1rem 3rem;
  border-radius: 2rem;
  background-image: linear-gradient(to right, #48b98d 15%, #0185cf 100%);
}

@media (max-width: 1200px) {
  .homepage-gallery-container .gallery-text-section .text h1 {
    font-size: 1.5rem;
    padding-bottom: 1rem;
  }
  .homepage-gallery-container .gallery-text-section .text p {
    font-size: 1rem;
  }
  .homepage-gallery-container .gallery-text-section .image img,
  .homepage-gallery-container .gallery-text-section .image .image-bg {
    width: 20rem;
    height: 20rem;
  }
  .homepage-gallery-container .gallery-text-section .text {
    padding-right: 5rem;
  }

  .homepage-gallery-container .gallery-text-section .text.less {
    padding-right: 4rem;
  }
  .homepage-gallery-container .gallery-text-section .text.right {
    padding-right: 0rem;
    padding-left: 5rem;
  }
}

@media (max-width: 768px) {
  .homepage-gallery-container .container-wrapper > h1 {
    padding-bottom: 3rem;
  }

  .homepage-gallery-container .container {
    padding: 1.5rem;
  }
  .homepage-gallery-container h1 {
    font-size: 1.5rem;
  }
  .homepage-gallery-container .images-container {
    display: none;
  }

  .homepage-gallery-container .gallery-text-section.center {
    flex-direction: column;
  }
  .homepage-gallery-container .gallery-text-section {
    flex-direction: column-reverse;
    padding: 0;
  }
  .homepage-gallery-container .gallery-text-section .image img,
  .homepage-gallery-container .gallery-text-section .image .image-bg {
    width: 15rem;
    height: 15rem;
  }

  .homepage-gallery-container .gallery-text-section .text h1,
  .homepage-gallery-container .gallery-text-section .text p {
    text-align: center;
    max-width: 100%;
  }

  .homepage-gallery-container .gallery-text-section .text {
    max-width: 30rem;
    margin: auto;
    margin-block: 3rem;
  }

  .homepage-gallery-container .gallery-text-section .text,
  .homepage-gallery-container .gallery-text-section .text.less,
  .homepage-gallery-container .gallery-text-section .text.right {
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .homepage-gallery-container .gallery-text-section .text.less h1 {
    margin: auto;
  }
  .homepage-gallery-container .btn-large {
    font-size: 1rem;
    padding: 1rem 2rem;
    border-radius: 2rem;
  }
  .homepage-gallery-container .button-container {
    padding-top: 1rem;
  }
}
