.new-request-doctor-dashboard-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new-request-doctor-dashboard-card span {
  padding-inline: 0.5rem;
  font-size: 1.3rem;
}

.new-request-doctor-dashboard-card > div:nth-child(2) {
  width: fit-content;
}

.multiselect-outer-styles .searchWrapper,
.multiselect-outer-styles .react-tagsinput {
  border-color: red !important;
}
