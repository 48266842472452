/* 


patient header styles


*/

.patient-signup-header {
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* font-family: 'Libre Baskerville', serif;
  font-family: 'Montserrat', sans-serif;
  font-family: 'Onest', sans-serif; */

.patient-signup-header .form-wrapper .btn-small {
  background-color: transparent;
  border: none;
  font-weight: bold;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.9rem;
  width: fit-content;
  margin-left: auto;
  width: 11rem;
}

.patient-signup-header .form-wrapper .btn-small.color-gradient {
  background-image: linear-gradient(
    to right,
    rgba(72, 185, 142, 1) 15%,
    rgba(1, 132, 207, 1) 100%
  );
}

.patient-signup-header .form-bg {
  background-image: url(../../assets/newRegister/bg1.jpg);
  background-position: center;
  background-size: cover;
  width: 100%;
  flex: 1;
  display: flex;
}
.patient-signup-header .overlay {
  background-image: linear-gradient(
    to top,
    rgba(72, 185, 142, 0.7) 15%,
    rgba(1, 132, 207, 0.7) 100%
  );
  width: 100%;
  min-height: 100%;
}

.patient-signup-header .form-wrapper-outer {
  max-width: 1600px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.patient-signup-header .form-wrapper {
  max-width: 30rem;
  background-color: #f4f4f4;
  margin-left: auto;
  margin-right: 7rem;
  padding: 3rem;
  height: 100%;
}

.patient-signup-header .form-wrapper > h1 {
  text-align: center;
  font-size: 1.5rem;
  font-family: "Libre Baskerville", serif;
  text-decoration: underline;
  padding-bottom: 5px;
  padding-inline: 10px;
  width: fit-content;
  margin: auto;
  border-bottom: 2px solid black;
  margin-bottom: 1.5rem;
}

.patient-signup-header .form-label {
  font-family: "Onest", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.patient-signup-header p {
  margin: 0;
}

.patient-signup-header .form-input {
  background-color: #f4f4f4;
  border-radius: 0;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 1px black;
  padding: 0.5rem;
  padding-block: 6px;
  font-size: 0.8rem;
}

.patient-signup-header .form-input svg {
  fill: red;
}

.patient-signup-header .phone-input {
  padding: 0;
}

.patient-signup-header .gender {
  height: 34px;
}

.patient-signup-header .phone-input .form-control {
  background-color: transparent;
  border: none;
  outline: none;
}

.patient-signup-header .phone-input .form-control:focus {
  box-shadow: none;
}

.patient-signup-header .phone-input .flag-dropdown {
  border: none;
  outline: none;
  box-shadow: none;
}

.patient-signup-header .form-wrapper .buttons-container {
  display: flex;
  gap: 2rem;
  margin: auto;
  justify-content: center;
  padding-top: 1.5rem;
  border-top: 1px solid rgb(212, 212, 212);
}

.patient-signup-header .form-wrapper .buttons-container span,
.patient-signup-header .form-wrapper .buttons-container button {
  font-size: 14px;
  border: none;
  font-family: "Onest", sans-serif;
  font-weight: 500;
}

.patient-signup-header .form-wrapper .buttons-container button {
  border-bottom: 1px solid black;
}

@media (max-width: 768px) {
  .patient-signup-header .form-wrapper {
    margin-right: 0rem;
    margin: auto;
  }
  .patient-signup-header .form-wrapper .buttons-container span,
  .patient-signup-header .buttons-container button {
    font-size: 12px;
  }
  .patient-signup-header .form-wrapper .buttons-container {
    gap: 1rem;
  }
}

/* 



patient about section styles 


*/

.patient-signup-about {
  width: 100%;
}

.patient-signup-about .container {
  max-width: 900px;
  padding-inline: 3rem;
  padding-block: 5rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.patient-signup-about .container h1 {
  font-size: 2.5rem;
  font-family: "Libre Baskerville", serif;
  text-align: center;
  color: #1a2249;
}

.patient-signup-about .container h2 {
  font-family: "Onest", sans-serif;
  font-size: 1.3rem;
  padding-block: 1.5rem;
  text-align: center;
  color: #1a2249;
}

.patient-signup-about .container p {
  font-family: "Onest", sans-serif;
  font-size: 1.1rem;
  text-align: center;
  color: #1a2249;
  padding-bottom: 1.5rem;
}

.patient-signup-about .buttons-container {
  display: flex;
  gap: 2rem;
  margin: auto;
  justify-content: center;
  padding-top: 2.5rem;
  border-top: 1px solid rgb(212, 212, 212);
  padding-inline: 3rem;
  align-items: center;
}

.patient-signup-about .buttons-container span,
.patient-signup-about .buttons-container button {
  font-size: 1rem;
  border: none;
  font-family: "Onest", sans-serif;
  font-weight: 500;
}

.patient-signup-about .btn-small {
  background-color: transparent;
  border: none;
  font-weight: bold;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  font-size: 0.9rem;
  width: fit-content;
}

.patient-signup-about .btn-small.color-gradient {
  background-image: linear-gradient(
    to right,
    rgba(72, 185, 142, 1) 15%,
    rgba(1, 132, 207, 1) 100%
  );
}

@media (max-width: 768px) {
  .patient-signup-about .container {
    padding-inline: 1.5rem;
    /* padding-block: 5rem; */
  }

  .patient-signup-about .container h1 {
    font-size: 2rem;
  }

  .patient-signup-about .container h2 {
    font-size: 1rem;
  }

  .patient-signup-about .container p {
    font-size: 0.9rem;
  }
  .patient-signup-about .buttons-container {
    display: flex;
    gap: 1rem;
    margin: auto;
    justify-content: space-between;
    padding-top: 1.5rem;
    border-top: 1px solid rgb(212, 212, 212);
    padding-inline: 0rem;
    align-items: center;
  }
  .patient-signup-about .buttons-container span,
  .patient-signup-about .buttons-container button {
    font-size: 0.7rem;
    border: none;
    font-family: "Onest", sans-serif;
    font-weight: 500;
  }
  .patient-signup-about .btn-small {
    background-color: transparent;
    border: none;
    font-weight: bold;
    color: white;
    padding: 0.5rem 1rem;
  }
}

/*


icons section styles


*/

.patient-signup-icons .about-container {
  padding-block: 3rem;
  background-image: linear-gradient(to right, #48b98d 15%, #0185cf 100%);
}

.patient-signup-icons .about-container-inner {
  max-width: 1300px;
  margin: auto;
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4rem;
}

.patient-signup-icons .about-container .item-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.patient-signup-icons .about-container .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 8rem;
}

.patient-signup-icons .about-container h1 {
  font-size: 4rem;
  font-family: "Onest", sans-serif;
  color: white;
  margin: 0;
  font-weight: 700;
}

.patient-signup-icons .about-container h2 {
  font-size: 1.2rem;
  font-weight: 300;
  font-family: "Onest", sans-serif;
  color: white;
  text-align: center;
}

.patient-signup-icons .about-container .logo {
  margin-inline: 1.5rem;
}

.patient-signup-icons .about-container img {
  height: 5rem;
  width: 5rem;
  object-fit: contain;
}

@media (max-width: 768px) {
  .patient-signup-icons .about-container-inner {
    padding-inline: 0rem;
  }
}

.email-sent-notification-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.479);
  z-index: 100;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-sent-notification-container-inner {
  padding: 3rem;
  margin: 1.5rem;
  background-color: #f4f4f4;
}
