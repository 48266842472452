* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif;
  text-decoration: none !important;
}

:root {
  --main-text-color: rgba(14, 38, 71, 1);
  --main-button-color: rgba(4, 156, 213, 1);
  --main-bg-color: rgba(232, 249, 255, 1);
  --main-bg1-color: rgba(98, 194, 154, 0.75);
  --main-bg2-color: rgba(4, 156, 213, 0.21);
  --main-text2-color: #049cd5;
  --main-text3-color: white;
  --main-text4-color: #737373;
  --main-text5-color: #f9f9f9;
}

.mTc {
  color: var(--main-text-color);
}

.mtc1 {
  color: var(--main-bg1-color);
}

.input-field::placeholder {
  font-size: 13px;
}

@media (max-width: 576px) {
  .main-text {
    font-size: 10px;
  }

  .main-img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .main-text {
    font-size: 10px;
  }

  .main-img {
    width: 50%;
  }
}

@media (max-width: 320) {
  .main-text {
    font-size: 5;
  }
}

/* Scroll Bar */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-button-color);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Scrollbar */

/* Accordion Button Arrow Style */
.accordion-button::before {
  width: 1rem !important;
  height: 1rem !important;
  background-size: 1rem !important;
}

.accordion-button::after {
  width: 1rem !important;
  height: 1rem !important;
  background-size: 1rem !important;
}

.new_appoint_disease {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  text-align: right;
  text-transform: capitalize;
}

.appointment_detail_cancelled_by {
  padding: 20px 20px;
  /* border: 1px solid #f43f5e;
  background-color: #fecaca; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 20px 20px 0;
}

.appointment_detail_cancelled_by p {
  margin: 0;
  padding: 0;
}


div:where(.swal2-container) .swal2-html-container {
  font-size: 14px !important;
  text-wrap: balance !important;
  line-height: 1.5em !important;
}