.homepage-for-doctors-container-outer {
  width: 100%;
  height: 100%;
}

.homepage-for-doctors-container {
  height: 100vh;
  margin: auto;
  max-width: 1600px;
  display: flex;
  width: 100%;
  overflow: hidden;
}

/* font-family: 'Libre Baskerville', serif;
      font-family: 'Montserrat', sans-serif;
      font-family: 'Onest', sans-serif; */

.homepage-for-doctors-container .text {
  height: 100vh;
  max-width: 50%;
  padding-inline: 10rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 7rem;
}

.homepage-for-doctors-container .text .btn-large {
  border: none;
  font-weight: bold;
  color: white;
  padding: 0.7rem 2.5rem;
  border-radius: 2rem;
  background-image: linear-gradient(to right, #48b98d 15%, #0185cf 100%);
  width: fit-content;
}

.homepage-for-doctors-container .text h1 {
  font-size: 2.5rem;
  color: rgb(26, 34, 73);
  font-family: "Libre Baskerville", serif;
  text-transform: uppercase;
  font-weight: 700;
  text-align: left;
  max-width: 1000px;
  width: 100%;
}

.homepage-for-doctors-container .text p {
  font-size: 1.2rem;
  color: rgb(67, 72, 95);
  font-family: "Onest", sans-serif;
  padding-block: 1.5rem;
}

.homepage-for-doctors-container .images {
  height: 100vh;
  max-width: 50%;
  /* background-color: red; */
  width: 100%;
  /* flex: 1; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.homepage-for-doctors-container .images img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.homepage-for-doctors-container .images .image-container-01,
.homepage-for-doctors-container .images .image-container-02 {
  width: 50%;
  height: 115%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.homepage-for-doctors-container .images .image-container-01 {
  transform: translateY(50px);
}
.homepage-for-doctors-container .images .image-container-02 {
  transform: translateY(-50px);
}

.homepage-for-doctors-container .images .image-wrapper {
  flex: 1;
}

.homepage-for-doctors-container-outer .about-container {
  padding-block: 3rem;
  background-image: linear-gradient(to right, #48b98d 15%, #0185cf 100%);
}

.homepage-for-doctors-container-outer .about-container-inner {
  max-width: 1300px;
  margin: auto;
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4rem;
}

.homepage-for-doctors-container-outer .about-container .item-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage-for-doctors-container-outer .about-container .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 8rem;
}

.homepage-for-doctors-container-outer .about-container h1 {
  font-size: 4rem;
  font-family: "Onest", sans-serif;
  color: white;
  margin: 0;
  font-weight: 700;
}

.homepage-for-doctors-container-outer .about-container h2 {
  font-size: 1.2rem;
  font-weight: 300;
  font-family: "Onest", sans-serif;
  color: white;
  text-align: center;
}

.homepage-for-doctors-container-outer .about-container .logo {
  margin-inline: 1.5rem;
}

.homepage-for-doctors-container-outer .about-container img {
  height: 5rem;
  width: 5rem;
  object-fit: contain;
}

@media (max-width: 1200px) {
  .homepage-for-doctors-container .text {
    padding-inline: 5rem;
  }
}

@media (max-width: 900px) {
  .homepage-for-doctors-container .text h1 {
    font-size: 2rem;
  }

  .homepage-for-doctors-container .text p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .homepage-for-doctors-container .text,
  .homepage-for-doctors-container .images {
    max-width: 100%;
    width: 100%;
    height: fit-content;
  }

  .homepage-for-doctors-container {
    flex-direction: column-reverse;
  }

  .homepage-for-doctors-container .text {
    padding: 3rem 1.5rem;
    align-items: center;
  }

  .homepage-for-doctors-container .images .image-container-02 {
    flex-direction: row;
    height: 10rem;
    width: 100%;
    gap: 0rem;
    transform: none;
  }

  .homepage-for-doctors-container .images .image-container-01 {
    display: none;
  }
  .homepage-for-doctors-container .text h1 {
    text-align: center;
  }

  .homepage-for-doctors-container .text p {
    text-align: center;
  }
  .homepage-for-doctors-container {
    height: fit-content;
  }
  .homepage-for-doctors-container-outer .about-container .logo {
    margin-inline: 0rem;
  }
  .homepage-for-doctors-container-outer .about-container .logo img {
    height: 4rem;
    width: 4rem;
  }
  .homepage-for-doctors-container-outer .about-container-inner {
    gap: 2rem;
  }
  .homepage-for-doctors-container-outer .about-container .text h1 {
    font-size: 2rem;
  }
  .homepage-for-doctors-container-outer .about-container .text h2 {
    font-size: 1rem;
  }
  .homepage-for-doctors-container-outer .about-container .text {
    flex: 1;
  }
}
