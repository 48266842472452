.cf_container {
  border-width: 1px;
  border-color: rgba(219, 234, 254, 1);
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cf_container p {
  margin: 0;
}

/* CHAT HEADING */
.cf_heading {
  padding: 1rem;
}

/* CHAT BODDY */

.cf_body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 1rem;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  height: 100%;
}

.cf_body > div {
  padding: 3px;
  border-radius: 8px;
  background: #e7e5e4;
  min-width: 200px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 400px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.cf_body > div a {
  color: black !important;
  display: flex;
  gap: 4px;
  align-items: center;
  margin: 3px 3px 0 3px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 45px;
}

.cf_body > div img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 7px;
}

.cf_body > div.active {
  align-self: flex-end;
  background-color: #06b6d4;
  color: white;
}

.cf_body > div.active a {
  color: white !important;
}

.cf_body > div.active a svg {
  font-size: 24px;
}

.cf_body > div a,
.cf_body > div p {
  padding: 5px;
  word-wrap: break-word;
}

.chat_time_stamp {
  font-size: 12px;
  width: 100%;
  display: block;
  text-align: right;
  opacity: 0.7;
  padding: 3px 10px 3px 3px;
}

/* SEND MESSAGE CONTAINER */

.cf_input form {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 16px 16px 16px;
}

.cf_input form > input {
  margin-right: auto;
  color: #475569;
  border: 1px solid #475569;
  border-radius: 30px;
  padding: 10px;
  font-size: 14px;
  outline: transparent;
  width: 100%;
}

.cf_input form > input::placeholder {
  color: #475569;
}

.cf_input input[type="file"] {
  display: none;
}

.cf_input label {
  color: #06b6d4;
  cursor: pointer;
}

.cf_input label:first-child {
  margin-left: 5px;
}

.cf_input button {
  border: none;
  outline: transparent;
  box-shadow: none;
  color: #06b6d4;
}

/* CHAT LOADER SPINNER */

.cf_heading {
  display: flex;
  gap: 10px;
  align-items: center;
}

.chat_header_image {
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  color: #06b6d4;
}

.chat_header_image img,
.chat_header_image svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.chat_header_image img {
  object-fit: cover;
  border-radius: 50%;
}

.cf_heading h3 {
  font-size: 16px;
  margin: 0;
}

.chat_spinner {
  animation: spin 1s linear infinite;
}

.close_chat_button {
  border: none;
  background: #fecdd3;
  color: #f43f5e;
  box-shadow: var(--box-shadow);
  padding: 7px 20px;
  border-radius: 30px;
  outline: transparent;
  transition: all 0.1s ease;
  margin-left: auto;
  display: none;
}

/*  */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1200px) {
  .cf_body > div {
    max-width: 250px;
  }

  .cf_container {
    position: fixed;
    transform: translateX(-100%);
    width: min(100vw, 400px);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    transition: all 0.3s ease;
  }

  .cf_container.show {
    transform: translateX(0);
  }

  .close_chat_button {
    display: block !important;
  }
}
