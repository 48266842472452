/* CircularCheckbox.css */
.circle-checkbox {
  width: 20px;
  /* Adjust the size as needed */
  height: 20px;
  /* Adjust the size as needed */
  border: 2px solid var(--main-text4-color);
  border-radius: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.circle-checkbox.checked {
  background-color: var(--main-button-color);
  border-color: var(--main-button-color);
}

.checkbox-inner {
  width: 60%;
  height: 60%;
  background-color: #fff;
  border-radius: 50%;
  display: none;
}

.circle-checkbox.checked .checkbox-inner {
  display: block;
}

/* ---------------------------- */
.font-p1 {
  font-size: 13px;
}

.font-p2 {
  font-weight: bold;
}

.Button {
  background-color: var(--main-button-color);
  border: 1px solid var(--main-button-color);
  color: var(--main-text3-color);
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}

.Button:hover {
  background-color: var(--main-text-color);
  border: 1px solid var(--main-text-color);
  color: var(--main-text3-color);
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}

.questions-check-vertically {
  max-width: 800px;
  max-height: 290px;
  flex-wrap: wrap;
}

/* Change these styles */

.appointment_questions {
  padding: 3rem 2rem !important;
  margin: 10px;
  border-radius: 0.25rem;
  border: 1px solid #dee2e6 !important;
}

.appointment_questions h6.mTc {
  text-align: center;
  margin-inline: auto;
}

.appointment_questions .appointment_questions_heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.appointment_questions .appointment_questions_heading h3 {
  font-size: 20px;
}

.appointment_questions .appointment_questions_heading span {
  font-size: 14px;
  color: rgba(4, 156, 213, 1);
}

.appointment_questions_wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.appointment_questions_wrapper .appointment_wrapper_inner {
  margin-top: 3rem !important;
}
